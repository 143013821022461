import React, { useState } from 'react';
import Modal from '../Modal.js';
import Img1 from '../../asset/kitchen/k1.jpg';
import Img2 from '../../asset/kitchen/K3.jpg';
import Img3 from '../../asset/kitchen/k12.jpg';
import Img4 from '../../asset/kitchen/K15.jpg';
import Img5 from '../../asset/kitchen/k16.jpg';
import Img6 from '../../asset/kitchen/K14.jpg';
import Img7 from '../../asset/kitchen/k17.jpg';
import Img8 from '../../asset/kitchen/k18.jpg';
import Img9 from '../../asset/kitchen/k12.jpg';
import Img10 from '../../asset/kitchen/K19.jpg';
import Img11 from '../../asset/kitchen/k8.jpg';
import Img12 from '../../asset/kitchen/K9.jpg';
// island
import Imgisland1 from '../../asset/kitchenIsland/k5.jpg';
import Imgisland2 from '../../asset/kitchenIsland/k8.jpg';
import Imgisland3 from '../../asset/kitchenIsland/k7.jpg';
import Imgisland4 from '../../asset/kitchenIsland/k6.jpg';
import Imgisland6 from '../../asset/kitchenIsland/ki3.jpg';
import Imgisland7 from '../../asset/kitchenIsland/ki4.jpg';
import Imgisland8 from '../../asset/kitchenIsland/ki2.jpg';
// dinning
import ImgDinning1 from '../../asset/dinningTable/dt6.jpg';
import ImgDinning2 from '../../asset/dinningTable/dt2.jpg';
import ImgDinning3 from '../../asset/dinningTable/dt3.jpg';
import ImgDinning4 from '../../asset/dinningTable/dt4.jpg';
import ImgDinning5 from '../../asset/dinningTable/dt5.jpg';
import ImgDinning6 from '../../asset/dinningTable/dt1.jpg';


const ShowFurniture = () => {

  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const kitchen = [
    [
      {
        id: 1,
        title: 'Modern Bed',
        heading: 'Kitchen Platform',
        description: 'A stylish and comfortable modern bed for your bedroom.',
        img: Img1,
      },
      {
        id: 2,
        title: 'Luxury Sofa',
        heading: 'Kitchen Platform',
        description: 'A luxury sofa with soft cushions for ultimate comfort.',
        img: Img2,
      },
      {
        id: 3,
        title: 'Wooden Wardrobe',
        heading: 'Kitchen Platform',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: Img3,
      },
      {
        id: 4,
        title: 'Wooden Wardrobe',
        heading: 'Kitchen Platform',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: Img4,
      },
      {
        id: 5,
        title: 'Wooden Wardrobe',
        heading: 'Kitchen Platform',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: Img5,
      },
      {
        id: 6,
        title: 'Wooden Wardrobe',
        heading: 'Kitchen Platform',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: Img6,
      },
      {
        id: 7,
        title: 'Wooden Wardrobe',
        heading: 'Kitchen Platform',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: Img7,
      },
      {
        id: 8,
        title: 'Wooden Wardrobe',
        heading: 'Kitchen Platform',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: Img8,
      },
      {
        id: 9,
        title: 'Wooden Wardrobe',
        heading: 'Kitchen Platform',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: Img9,
      },
      {
        id: 10,
        title: 'Wooden Wardrobe',
        heading: 'Kitchen Platform',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: Img10,
      },
      {
        id: 11,
        title: 'Wooden Wardrobe',
        heading: 'Kitchen Platform',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: Img11,
      },
      {
        id: 12,
        title: 'Wooden Wardrobe',
        heading: 'Kitchen Platform',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: Img12,
      }
    ],
    [
      {
        id: 1,
        title: 'Modern Bed',
        heading: 'Kitchen Island',
        description: 'A stylish and comfortable modern bed for your bedroom.',
        img: Imgisland1,
      },
      {
        id: 2,
        title: 'Luxury Sofa',
        heading: 'Kitchen Island',
        description: 'A luxury sofa with soft cushions for ultimate comfort.',
        img: Imgisland2,
      },
      {
        id: 3,
        title: 'Wooden Wardrobe',
        heading: 'Kitchen Island',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: Imgisland3,
      },
      {
        id: 4,
        title: 'Wooden Wardrobe',
        heading: 'Kitchen Island',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: Imgisland4,
      },
      {
        id: 5,
        title: 'Wooden Wardrobe',
        heading: 'Kitchen Island',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: Imgisland6,
      },
      {
        id: 6,
        title: 'Wooden Wardrobe',
        heading: 'Kitchen Island',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: Imgisland7,
      },
      {
        id: 7,
        title: 'Wooden Wardrobe',
        heading: 'Kitchen Island',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: Imgisland8,
      }
    ],
    [
      {
        id: 1,
        title: 'Modern Bed',
        heading: 'Dinning Table',
        description: 'A stylish and comfortable modern bed for your bedroom.',
        img: ImgDinning1,
      },
      {
        id: 2,
        title: 'Luxury Sofa',
        heading: 'Dinning Table',
        description: 'A luxury sofa with soft cushions for ultimate comfort.',
        img: ImgDinning2,
      },
      {
        id: 3,
        title: 'Wooden Wardrobe',
        heading: 'Dinning Table',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: ImgDinning3,
      },
      {
        id: 4,
        title: 'Wooden Wardrobe',
        heading: 'Dinning Table',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: ImgDinning4,
      },
      {
        id: 5,
        title: 'Wooden Wardrobe',
        heading: 'Dinning Table',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: ImgDinning5,
      },
      {
        id: 6,
        title: 'Dinning Table',
        heading: 'Dinning Table',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: ImgDinning6,
      }
    ]
  ];
  return (
    <div style={{ backgroundColor: '' }}>
      <div className="container">
        <div className="row g-4">
          {kitchen.map((category, index) => (
            <React.Fragment key={index}>
              {/* Print category heading */}
              <h2 className="col-12 text-center pt-3">
                {category[0]?.heading || "Default Heading"}
              </h2>
              {category.map((item) => (
                <div key={item.id} className="col-12 col-sm-6 col-md-4 col-lg-3">
                  <div className="card" >
                    <img className="card-img-top lazy" height={250}  src={item.img} alt={item.title} />
                    <div className="card-body">
                      <h5 className="card-title">{item.title}</h5>
                      <p className="card-text">{item.description}</p>
                      <button className="btn estimateBtn " style={{ backgroundColor: '#B482DA' , borderRadius: "8px", display: "inline-block", color: "white", }} onClick={openModal}>
                        Get Quote
                      </button>
                      <Modal showModal={showModal} closeModal={closeModal} />
                    </div>
                  </div>
                </div>
              ))}
              {/* Insert <hr> after each category */}
              <div className="col-12 ">
                <hr className='p-0 m-0' />
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ShowFurniture;
