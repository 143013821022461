import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import '../style/ShowQuote.css';
import Img1 from '../asset/quoteImage/1bhk.jpg';
import Img2 from '../asset/quoteImage/2bhk.png';
import Img3 from '../asset/quoteImage/3bhk2.jpg';
import ImgEssential from '../asset/quoteImage/essentail.avif';
import ImgPremium from '../asset/quoteImage/premium.avif';
import ImgLuxury from '../asset/quoteImage/luxury.avif';

const MultiForm = () => {

  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({ designType: "", subDesignType: "", quality: "" });
  const [selectedVegetables, setSelectedVegetables] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false); // Track the Next button state
  const navigate = useNavigate();
  const [pinCode, setPinCode] = useState('');
  const [areaName, setAreaName] = useState('');
  const [error, setError] = useState('');
  const [otherQualityPrices, setOtherQualityPrices] = useState([]);
  const [vegetables, setVegetables] = useState([]);
  const [pricesByQuality, setPricesByQuality] = useState({});
  const [isExpanded, setIsExpanded] = useState(false);





  useEffect(() => {
    const essentialVegetables = [
      { name: "Carrot", price: 2.5 },
      { name: "Broccoli", price: 3.0 },
      { name: "Spinach", price: 1.8 },
      { name: "Potato", price: 1.2 },
    ];
    const premiumVegetables = [
      { name: "Carrot", price: 3.75 },
      { name: "Broccoli", price: 4.5 },
      { name: "Spinach", price: 2.7 },
      { name: "Potato", price: 1.8 },
    ];
    const luxuryVegetables = [
      { name: "Carrot", price: 5.0 },
      { name: "Broccoli", price: 6.0 },
      { name: "Spinach", price: 3.6 },
      { name: "Potato", price: 2.4 },
    ];

    const qualities = {
      Essential: essentialVegetables,
      Premium: premiumVegetables,
      Luxury: luxuryVegetables,
    };

    setVegetables(qualities[formData.quality]);

    const selectedTotal = Object.values(selectedVegetables).reduce(
      (sum, veg) => sum + veg.price * veg.quantity,
      0
    );
    setTotalPrice(selectedTotal);

    const otherQualityTotals = Object.keys(qualities).reduce((totals, quality) => {
      if (quality !== formData.quality) {
        const total = Object.values(selectedVegetables).reduce((sum, veg) => {
          const correspondingVeg = qualities[quality].find(
            (v) => v.name === veg.name
          );
          return correspondingVeg
            ? sum + correspondingVeg.price * veg.quantity
            : sum;
        }, 0);
        totals[quality] = total; // Store totals as an object
      }
      return totals;
    }, {});

    setOtherQualityPrices(otherQualityTotals);
  }, [selectedVegetables, formData.quality]);



  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleQuantityChange = (veggie, delta) => {
    setSelectedVegetables((prev) => {
      const updated = { ...prev };
      if (!updated[veggie.name]) updated[veggie.name] = { ...veggie, quantity: 0 };
      updated[veggie.name].quantity = Math.max(0, updated[veggie.name].quantity + delta);
      if (updated[veggie.name].quantity === 0) delete updated[veggie.name];
      return updated;
    });
  };

  const nextStep = () => {
    if (validateCurrentStep()) {
      setIsNextButtonDisabled(true); // Disable the Next button while processing
      setTimeout(() => {
        setIsNextButtonDisabled(false); // Re-enable after a short delay
        setCurrentStep((prev) => prev + 1); // Move to the next step
      }, 1000); // Delay to prevent double-clicking
    }
  };

  const validateCurrentStep = () => {
    if (currentStep === 1 && !formData.designType) {
      alert("Please select a design type.");
      return false;
    }
    if (currentStep === 1 && formData.designType === "Full Home" && !formData.subDesignType) {
      alert("Please select a sub-option for Full Home.");
      return false;
    }
    if (currentStep === 2 && !formData.quality) {
      alert("Please select a package quality.");
      return false;
    }
    if (currentStep === 3 && formData.designType === "Full Home" && Object.keys(selectedVegetables).length === 0) {
      alert("Please select at least one vegetable.");
      return false;
    }
    return true;
  };

  const [formData2, setFormData2] = useState({ name: "", email: "", phone: "", address: "", pincode: "" });
  const [purchesed, setPurchesed] = useState();
  const handleChange2 = (e) => {
    setFormData2({ ...formData2, [e.target.id]: e.target.value });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    nextStep();
    if (validateCurrentStep()) {
      console.log("Form submitted successfully:", formData, selectedVegetables, formData2);
      alert("Form submitted successfully!");

    }
  };

  const handleHome = () => {
    navigate('/');
  }

  const items = ["Kitchen", "Bedroom", "Living room", "Full Home"];
  const [currentItem, setCurrentItem] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentItem((prevItem) => (prevItem + 1) % items.length);
    }, 2000); // Change every 2 seconds
    return () => clearInterval(interval);
  }, [items.length]);

  // Other iterior price excempt full home
  useEffect(() => {
    // Updated arrays with the "material" property
    const essential = [
      { name: "Kitchen", price: 2.5, material: "Wood" },
      { name: "Wardrobe", price: 3.0, material: "Plywood" },
      { name: "TVunit", price: 1.8, material: "Fiberboard" },
      { name: "Bedroom", price: 1.2, material: "Particleboard" },
    ];

    const premium = [
      { name: "Kitchen", price: 3.75, material: "Granite" },
      { name: "Wardrobe", price: 4.5, material: "Teakwood" },
      { name: "TVunit", price: 2.7, material: "High-Quality Laminate" },
      { name: "Bedroom", price: 1.8, material: "Hardwood" },
    ];

    const luxury = [
      { name: "Kitchen", price: 5.0, material: "Marble" },
      { name: "Wardrobe", price: 6.0, material: "Rosewood" },
      { name: "TVunit", price: 3.6, material: "Veneer" },
      { name: "Bedroom", price: 2.4, material: "Solid Oak" },
    ];

    if (
      currentStep === 3 &&
      ["Bedroom", "Kitchen", "Wardrobe", "TVunit"].includes(formData.designType)
    ) {
      // Determine the quality array
      let selectedQualityArray = [];
      if (formData.quality === "Essential") selectedQualityArray = essential;
      else if (formData.quality === "Premium") selectedQualityArray = premium;
      else if (formData.quality === "Luxury") selectedQualityArray = luxury;

      // Find the selected item for the design type
      const selectedItem = selectedQualityArray.find(
        (item) => item.name === formData.designType
      );

      if (selectedItem) {
        // Update the state with all necessary details
        setTotalPrice(selectedItem.price); // Update the price
        setPurchesed((prev) => ({
          ...prev,
          selectedItemDetails: {
            quality: formData.quality,
            name: selectedItem.name,
            material: selectedItem.material,
            price: selectedItem.price,
          },
        }));
      } else {
        // Handle case where no item is found
        setTotalPrice(0);
        setPurchesed((prev) => ({
          ...prev,
          selectedItemDetails: null,
        }));
      }



      // Log prices and materials for other qualities
      const otherQualities = {
        Essential: essential,
        Premium: premium,
        Luxury: luxury,
      };

      const pricesByQuality = {}; // Initialize an empty object

      Object.keys(otherQualities).forEach((quality) => {
        if (quality !== formData.quality) {
          const item = otherQualities[quality].find(
            (q) => q.name === formData.designType
          );
          if (item) {
            pricesByQuality[quality] = {
              price: item.price,
              material: item.material,
              name: item.name
            }; // Store the quality, price, and material
          }
        }
      });

      setPricesByQuality(pricesByQuality);

    }
  }, [currentStep, formData, setTotalPrice, purchesed, setPricesByQuality]);

  // Trigger fetchArea whenever pinCode changes
  useEffect(() => {
    const validatePinCode = (pinCode) => {
      const regex = /^[1-9]{1}[0-9]{5}$/;
      return regex.test(pinCode);
    };

    const fetchArea = async (pinCode) => {
      if (!validatePinCode(pinCode)) {
        setError('Please enter a valid 6-digit pin code');
        setAreaName('');
        return;
      }

      setError('');

      try {
        const response = await axios.get(`https://api.postalpincode.in/pincode/${pinCode}`);

        if (response.data[0].Status === 'Success') {
          const areaDetails = response.data[0].PostOffice[0];
          setAreaName(`${areaDetails.Name}, ${areaDetails.District}, ${areaDetails.State}`);
        } else {
          setAreaName('');
          setError('No data found for the given pin code');
        }
      } catch (err) {
        setAreaName('');
        setError('Failed to fetch data. Please try again.');
      }
    };

    if (pinCode.trim() === '') {
      setAreaName('');
      setError('');
    } else {
      fetchArea(pinCode);
    }
  }, [pinCode]);

  const handleShowMore = (blockId) => {
    setIsExpanded((prev) => !prev); // Toggle the state
  };


  return (
    <div>
      <div className="container mt-2 mb-5 pb-4" style={{
        border: '1px solid black',
        position: "relative", // Makes the container a positioning context
        width: "auto", // Set the width of the container
        margin: "0 auto", // Centers the container
        overflow: "hidden", // Ensures the button doesn't overflow the container
      }}>
        <h4 className="text-center" style={{ color: 'red' }}>Interior Price Calculator</h4>
        <p className="text-center" style={{ fontWeight: "bold", fontSize: "1.5rem", overflow: "hidden", position: "relative", color: '' }} > Determine how much it will cost to renovate your interiors{" "}
          <span className="animated-text" style={{ color: 'red' }}>{items[currentItem]}</span>
        </p>
        <hr className=" p-0 m-0" />
        <form onSubmit={handleSubmit}>
          {currentStep === 1 && (
            <div className="text-center ">
              <p style={{ fontWeight: "bolder", fontSize: "25px" }} className="text-center">Select the Type of Interior Design</p>
              <div className="radio-group " style={{ display: 'flex', alignContent: 'center' }}>
                <label><input type="radio" name="designType" value="Full Home" checked={formData.designType === "Full Home"} onChange={(e) => { handleChange(e) }} />
                  Full Home </label>
                <label> <input type="radio" name="designType" value="Bedroom" checked={formData.designType === "Bedroom"} onChange={(e) => { handleChange(e) }} />
                  Bedroom</label>
                <label> <input type="radio" name="designType" value="Kitchen" checked={formData.designType === "Kitchen"} onChange={(e) => { handleChange(e) }} />
                  Kitchen </label>
                <label> <input type="radio" name="designType" value="TVunit" checked={formData.designType === "TVunit"} onChange={(e) => { handleChange(e) }} />
                  TV unit </label>
                <label>
                  <input type="radio" name="designType" value="Wardrobe" checked={formData.designType === "Wardrobe"} onChange={(e) => { handleChange(e) }} />
                  Wardrobe</label>
              </div>
              {formData.designType === "Full Home" && (
                <div className="">
                  <hr className="text-center mx-auto" style={{ width: '50%' }} />
                  <p style={{ color: 'red' }}>Select the type of Full Home:</p>

                  <div className="radio-options-container">
                    {/* 1BHK */}
                    <div className="radio-option">
                      <img src={Img1} alt="1BHK" className="image-style" />
                      <label className="mx-2">
                        <input type="radio" name="subDesignType" value="1BHK" checked={formData.subDesignType === "1BHK"} onChange={(e) => { handleChange(e) }} /> 1BHK
                      </label>
                    </div>

                    {/* 2BHK */}
                    <div className="radio-option">
                      <img src={Img2} alt="2BHK" className="image-style" />
                      <label className="mx-2">
                        <input type="radio" name="subDesignType" value="2BHK" checked={formData.subDesignType === "2BHK"} onChange={(e) => { handleChange(e) }} /> 2BHK
                      </label>
                    </div>

                    {/* 3BHK */}
                    <div className="radio-option">
                      <img src={Img3} alt="3BHK" className="image-style" />
                      <label className="mx-2">
                        <input type="radio" name="subDesignType" value="3BHK" checked={formData.subDesignType === "3BHK"} onChange={(e) => { handleChange(e) }} /> 3BHK
                      </label>
                    </div>
                  </div>
                </div>
              )}


            </div>
          )}

          {currentStep === 2 && (
            <div>
              <p style={{ fontWeight: "bolder", fontSize: "25px" }} className="text-center">Select Package Quality</p>

              <div className="radio-options-container">
                {/* Essential */}
                <div className="radio-option">
                  <img src={ImgEssential} alt="Essential" className="image-style2" />
                  <label className="mx-2">
                    <input type="radio" name="quality" value="Essential" checked={formData.quality === "Essential"} onChange={(e) => { handleChange(e) }} /> Essential
                  </label>
                </div>

                {/* Premium */}
                <div className="radio-option">
                  <img src={ImgPremium} alt="Premium" className="image-style2" />
                  <label className="mx-2">
                    <input type="radio" name="quality" value="Premium" checked={formData.quality === "Premium"} onChange={(e) => { handleChange(e) }} /> Premium
                  </label>
                </div>

                {/* Luxury */}
                <div className="radio-option">
                  <img src={ImgLuxury} alt="Luxury" className="image-style2" />
                  <label className="mx-2">
                    <input type="radio" name="quality" value="Luxury" checked={formData.quality === "Luxury"} onChange={(e) => { handleChange(e) }} /> Luxury
                  </label>
                </div>
              </div>
            </div>
          )}

          {currentStep === 3 && formData.designType === "Full Home" && (
            <div className="vegetable-selection-container">
              <p className="section-header text-center">Select the rooms you’d like us to design</p>
              {/* <!-- Button trigger modal --> */}
              <p className=" text-center readmore" data-bs-toggle="modal" data-bs-target="#exampleModal2">
                Read more
              </p>

              {/* <!-- Modal --> */}
              <div className="modal fade" id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true" >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1 className="modal-title fs-5" id="exampleModalLabel2">Modal title</h1>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum esse perferendis maiores eum nesciunt minus asperiores similique suscipit minima. Nam ea ab itaque saepe reprehenderit quam! Odit quidem inventore repellat laboriosam tempora odio sint. Non, ducimus. Hic labore veritatis exercitationem.
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      <button type="button" className="btn btn-primary">Save changes</button>
                    </div>
                  </div>
                </div>
              </div>


              {vegetables.map((veggie, index) => (
                <div key={index} className="vegetable-item">
                  <span className="vegetable-name">{veggie.name} </span>
                  <div className="quantity-buttons">
                    <button type="button" className="quantity-button" onClick={() => handleQuantityChange(veggie, -1)} disabled={!selectedVegetables[veggie.name] || selectedVegetables[veggie.name]?.quantity === 0} >
                      -
                    </button>
                    <input type="text" value={selectedVegetables[veggie.name]?.quantity || 0} readOnly className="quantity-input" />
                    <button type="button" className="quantity-button" onClick={() => handleQuantityChange(veggie, 1)} >
                      +
                    </button>
                  </div>
                </div>
              ))}
              <div className="total-price">

              </div>
            </div>
          )}

          {currentStep === 3 && ["Bedroom", "Kitchen", "Wardrobe", "TVunit"].includes(formData.designType) && (    //cng
            <div className="mx-auto" style={{ width: '400px', border: '1px solid black', padding: '20px', borderRadius: '10px', marginTop: '10px' }}>
              <p style={{ fontSize: '25px' }}>Your quotation is 1 step away</p>

              <div className="mb-3 form-floating">
                <input type="text" className="form-control" id="name" value={formData2.name} onChange={handleChange2} placeholder="Enter your name" required />
                <label htmlFor="name">Name</label>
              </div>

              <div className="mb-3 form-floating">
                <input type="email" className="form-control" id="email" value={formData2.email} onChange={handleChange2} placeholder="Enter your email" required />
                <label htmlFor="email">Email</label>
              </div>

              <div className="mb-3 form-floating">
                <input type="tel" className="form-control" id="phone" value={formData2.phone} onChange={handleChange2} placeholder="Enter your phone number" required />
                <label htmlFor="phone">Phone Number</label>
              </div>

              <div className="mb-3 form-floating">
                <textarea className="form-control" id="address" value={formData2.address} onChange={handleChange2} placeholder="Enter your address" rows="2" required></textarea>
                <label htmlFor="address">Address</label>
              </div>
              <div className="mb-3 form-floating">
                <input type="text" className="form-control" id="floatingInput" value={pinCode} onChange={(e) => {
                  setPinCode(e.target.value);
                  setFormData2({ ...formData2, pincode: e.target.value });
                }} placeholder="Enter pin code" />
                <label htmlFor="floatingInput">Enter pin code</label>
              </div>
              {error && <p style={{ color: 'red' }}>{error}</p>}
              {areaName && <p>Area: {areaName}</p>}
            </div>
          )}
          {currentStep === 4 &&
            ["Bedroom", "Kitchen", "Wardrobe", "TVunit"].includes(formData.designType) && (
              <div className="container">
                <div className="row justify-content-center ">
                  {/* First Block */}
                  <div className="col-12 col-md-6 d-flex justify-content-center align-items-center flex-column">
                    <h2 className="text-center">Price for Selected Quality</h2>
                    <div class="card" style={{width: '18rem'}}>
                      <img class="card-img-top" src={ImgLuxury} alt="Card  cap"/>
                        <div class="card-body">
                          <div className="table-responsive">
                            <table className="table table-borderless ">
                              <tbody>
                                <tr><td className="p-0">Price</td><td className="p-0 ps-3">${Object.entries(purchesed)[0][1]?.price || 0}</td></tr>
                                <tr><td className="p-0">Material</td><td className="p-0 ps-3">{Object.entries(purchesed)[0][1]?.material || 'N/A'}</td></tr>
                                <tr><td className="p-0">Name</td><td className="p-0 ps-3">{Object.entries(purchesed)[0][1]?.name || 'N/A'}</td></tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                    </div>
                  </div>

                  {/* Show More/Less Button */}
                  <div className="col-12 text-center ">
                    <p
                      onClick={handleShowMore}
                      style={{ cursor: 'pointer', color: 'blue', display: 'inline-block' }}
                    >
                      {isExpanded ? 'Show Less' : 'Show More'}{' '}
                      <i className={`fas fa-chevron-${isExpanded ? 'up' : 'down'}`}></i>
                    </p>
                  </div>

                  {/* Collapsible Section */}
                  {isExpanded && (
                    <div className="row justify-content-center w-100">
                      {/* Block 2 */}
                      {/* Block 2 */}
                      <div className="col-12 col-md-6  d-flex justify-content-center align-items-center flex-column">
                        <h3 className="text-center">Prices for {Object.entries(pricesByQuality)[0][0]}</h3>
                        <div className="table-responsive">
                          <table className="table table-borderless">
                            <tbody>
                              <tr><td className="p-0">Price</td><td className="p-0 ps-3">${Object.entries(pricesByQuality)[0][1]?.price || 0}</td></tr>
                              <tr><td className="p-0">Material</td><td className="p-0 ps-3">{Object.entries(pricesByQuality)[0][1]?.material || 'N/A'}</td></tr>
                              <tr><td className="p-0">Name</td><td className="p-0 ps-3">{Object.entries(pricesByQuality)[0][1]?.name || 'N/A'}</td></tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      {/* Block 3 */}
                      <div className="col-12 col-md-6  d-flex justify-content-center align-items-center flex-column">
                        <h3 className="text-center">Prices for {Object.entries(pricesByQuality)[1][0]}</h3>
                        <div className="table-responsive">
                          <table className="table table-borderless">
                            <tbody>
                              <tr><td className="p-0">Price</td><td className="p-0 ps-3">${Object.entries(pricesByQuality)[1][1]?.price || 0}</td></tr>
                              <tr><td className="p-0">Material</td><td className="p-0 ps-3">{Object.entries(pricesByQuality)[1][1]?.material || 'N/A'}</td></tr>
                              <tr><td className="p-0">Name</td><td className="p-0 ps-3">{Object.entries(pricesByQuality)[1][1]?.name || 'N/A'}</td></tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

            )}

          {/* dddddddddddddddddd */}
          {currentStep === 4 && formData.designType === "Full Home" && (    //cng
            <div className="mx-auto" style={{ width: '400px', border: '1px solid black', padding: '20px', borderRadius: '10px', marginTop: '10px' }}>
              <p style={{ fontSize: '25px' }}>Your quotation is 1 step away</p>

              <div className="mb-3 form-floating">
                <input type="text" className="form-control" id="name" value={formData2.name} onChange={handleChange2} placeholder="Enter your name" required />
                <label htmlFor="name">Name</label>
              </div>

              <div className="mb-3 form-floating">
                <input type="email" className="form-control" id="email" value={formData2.email} onChange={handleChange2} placeholder="Enter your email" required />
                <label htmlFor="email">Email</label>
              </div>

              <div className="mb-3 form-floating">
                <input type="tel" className="form-control" id="phone" value={formData2.phone} onChange={handleChange2} placeholder="Enter your phone number" required />
                <label htmlFor="phone">Phone Number</label>
              </div>

              <div className="mb-3 form-floating">
                <textarea className="form-control" id="address" value={formData2.address} onChange={handleChange2} placeholder="Enter your address" rows="2" required></textarea>
                <label htmlFor="address">Address</label>
              </div>
              <div className="mb-3 form-floating">
                <input type="text" className="form-control" id="floatingInput" value={pinCode} onChange={(e) => {
                  setPinCode(e.target.value);
                  setFormData2({ ...formData2, pincode: e.target.value });
                }} placeholder="Enter pin code" />
                <label htmlFor="floatingInput">Enter pin code</label>
              </div>
              {error && <p style={{ color: 'red' }}>{error}</p>}
              {areaName && <p>Area: {areaName}</p>}
            </div>
          )}
          {currentStep === 5 && formData.designType === "Full Home" && ( //cng
            <div>
              <h2>Price for Selected Quality: {totalPrice}</h2>
              <h3>Prices for Other Qualities:</h3>
              <ul>
                {Object.entries(otherQualityPrices).map(([quality, total]) => (
                  <li key={quality}>
                    Price for {quality} Quality: {total}
                  </li>
                ))}
              </ul>
            </div>
          )}

          <div className="text-center" style={{ marginTop: "20px" }}>
            {currentStep > 1 && (
              <button type="button" onClick={() => setCurrentStep((prev) => prev - 1)} style={{ borderRadius: "15px", padding: "5px 20px", border: "1px solid black", backgroundColor: "#E0E0E0", color: "black" }}>Back</button>
            )}
            {currentStep < 3 && ["Bedroom", "Kitchen", "Wardrobe", "TVunit"].includes(formData.designType) && (
              <button className="mx-3" type="button" onClick={nextStep} disabled={isNextButtonDisabled} style={{ borderRadius: "15px", padding: "5px 20px", border: "1px solid black", backgroundColor: "#FF6D69", color: "black" }}>Next</button>
            )}
            {currentStep === 3 && ["Bedroom", "Kitchen", "Wardrobe", "TVunit"].includes(formData.designType) && (
              <button className="mx-3" type="submit" disabled={isNextButtonDisabled} style={{ borderRadius: "15px", padding: "5px 20px", border: "1px solid black", backgroundColor: "#FF6D69", color: "black" }}>Submit</button>
            )}
            {currentStep === 4 && ["Bedroom", "Kitchen", "Wardrobe", "TVunit"].includes(formData.designType) && (
              <button className="mx-3" type="button" disabled={isNextButtonDisabled} onClick={handleHome} style={{ borderRadius: "15px", padding: "5px 20px", border: "1px solid black", backgroundColor: "#FF6D69", color: "black" }}>Goto Home</button>
            )}
            {currentStep < 4 && formData.designType === "Full Home" && (
              <button className="mx-3" type="button" onClick={nextStep} disabled={isNextButtonDisabled} style={{ borderRadius: "15px", padding: "5px 20px", border: "1px solid black", backgroundColor: "#FF6D69", color: "black" }}>Next</button>
            )}
            {currentStep === 4 && formData.designType === "Full Home" && (
              <button className="btn mx-3" type="submit" disabled={isNextButtonDisabled} style={{ borderRadius: "15px", padding: "5px 20px", border: "1px solid black", backgroundColor: "#FF6D69", color: "black" }}>Submit</button>
            )}
            {currentStep === 5 && formData.designType === "Full Home" && (
              <button className="btn mx-3" type="button" disabled={isNextButtonDisabled} onClick={handleHome} style={{ borderRadius: "15px", padding: "5px 20px", border: "1px solid black", backgroundColor: "#FF6D69", color: "black" }}>Goto Home</button>
            )}
          </div>

        </form>
      </div>
    </div>
  );
};

export default MultiForm;
