import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import img1 from '../../asset/Bathroom/7.jpg'; // Import your images
import img2 from '../../asset/Bathroom/13.jpg';
import img3 from '../../asset/Bathroom/16.jpg';
import img4 from '../../asset/Bathroom/15.jpg';

import '../../style/Bedroom.css';

const HighlightImages = () => {
    const listRef = useRef(null);

    const scrollLeft = () => {
        if (listRef.current) {
            listRef.current.scrollBy({
                top: 0,
                left: -150,
                behavior: 'smooth',
            });
        }
    };

    const scrollRight = () => {
        if (listRef.current) {
            listRef.current.scrollBy({
                top: 0,
                left: 150,
                behavior: 'smooth',
            });
        }
    };

    const images = [img1, img2, img3, img4];
    const titles = [
        'Vanity', 'Bathroom Cabinate', 'Towel Rack', 'Storage Shelf'
    ];
    const descriptions = [
        'Description for Image 1', 'Description for Image 2', 'Description for Image 3',
        'Description for Image 4'
    ];

    return (
        <div className='HBody'>
            <div className="scroll-container">
                <button className="scroll-button left" onClick={scrollLeft}></button>
                <ul className="list-group list-group-horizontal position-relative overflow-auto hide-scrollbar w-100" ref={listRef}>
                    {images.map((img, index) => (
                        <li key={index} className="list-group-item text-center p-3" style={{ backgroundColor: "#F6F6F5" }}>
                            <Link to={`/showfurniture?title=${titles[index]}&description=${descriptions[index]}&img=${img}`}>
                                <img src={img} alt={`Highlights ${index + 1}`} className="img-thumbnail" style={{ height: "200px", width: "auto", borderRadius: '10px' }} />
                            </Link>
                            <p className="image-title" style={{fontSize: '20px', fontWeight: 'bold'}}>{titles[index]}</p>
                            <p className="image-description p-0 m-0" style={{ textWrap: 'wrap' }}>{descriptions[index]}</p>
                            {titles[index] !== 'Storage Shelf' && (
                                <Link className='btn view-collection-btn align-content-center p-0 m-0' to={`/bathroom?title=${titles[index]}&description=${descriptions[index]}&img=${img}`} style={{ backgroundColor: '#626262', color: 'white' }}>
                                    View Collection
                                </Link>
                            )}
                        </li>
                    ))}
                </ul>
                <button className="scroll-button right" onClick={scrollRight}></button>
            </div>
        </div>

    );
};

export default HighlightImages;
