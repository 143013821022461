import React, { useState} from "react";
import BgImage from "../asset/BgImage1.jpg";
import Logo from "../asset/logo.png";
import "../style/Carousel.css";

export default function Carousel() {

    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        name: "",
        phone: "",
        whatsappUpdates: false,
        email: "",
        propertyName: "",
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    const handleNext = () => setStep(2);
    const handleBack = () => setStep(1);
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Form Data Submitted:", formData);
    };

 

    return (
        <div className="main" style={{ backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)), url(${BgImage})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center", width: "100%", overflow: "hidden", }} >
            <div className="row p-0 m-0">
                {/* Form Section */}
                <div  className={`col-12 col-md-6 p-0 m-0 animate-left`} >
                    <div className="container d-flex justify-content-left p-md-5 mt-2 p-lg-5 m-0">
                        <form onSubmit={handleSubmit} className="form-card bg-light p-4 m-0" style={{ width: "350px", height: "380px", borderRadius: "10px",backgroundColor: '#f8f9fa' }} >
                            <div className="d-flex justify-content-between align-items-center mb-4">
                                <h4 className="mb-0">{step === 1 ? "Designs for Every Budget" : "Share your email and property details"}</h4>
                                <div className="step-indicator">{step}/2</div>
                            </div>

                            {step === 1 ? (
                                <>
                                    <div className="mb-4">
                                        <input type="text" className="form-control" name="name" placeholder="Name" value={formData.name} onChange={handleChange} required />
                                    </div>
                                    <div className="mb-4 d-flex align-items-center">
                                        <input type="tel" className="form-control" name="phone" placeholder="Phone number" value={formData.phone} onChange={handleChange} required />
                                    </div>
                                    <div className="form-check mb-4">
                                        <input type="checkbox" className="form-check-input" id="whatsappUpdates" name="whatsappUpdates" checked={formData.whatsappUpdates} onChange={handleChange} />
                                        <label className="form-check-label" htmlFor="whatsappUpdates">Send me updates on WhatsApp</label>
                                    </div>
                                    <button type="button" className="btn btn-danger w-100 " onClick={handleNext}>Book free consultance</button>
                                </>
                            ) : (
                                <>
                                    <div className="mb-4">
                                        <input type="email" className="form-control" name="email" placeholder="Email ID" value={formData.email} onChange={handleChange} required />
                                    </div>
                                    <div className="mb-4">
                                        <input type="text" className="form-control" name="propertyName" placeholder="Property Name" value={formData.propertyName} onChange={handleChange} required />
                                    </div>
                                    <button type="button" className="btn btn-secondary w-100 mb-4" onClick={handleBack}>Back</button>
                                    <button type="submit" className="btn btn-danger w-100">Submit</button>
                                </>
                            )}

                            <p className="text-center mt-3 small" color="black">By submitting this form, you agree to the <a href="/" style={{color: '#0000FF'}}>privacy policy</a> & <a href="/"  style={{color: '#0000FF'}}>terms and conditions</a></p>
                        </form>
                    </div>
                </div>

                {/* Quote Section */}
                <div className={`col-12 col-md-6 m-0 mb-5 p-0 animate-right`} style={{ overflow: "hidden" }} >
                    <div className="quote pb-5" style={{ fontWeight: "bolder", fontSize: "30px", color: "white", display: "inline-block", }} >
                        <img src={Logo} className="logo" alt="Logo" />
                        <p>
                            Interior design is not just about making things beautiful, <br />
                            <span style={{ color: "yellow" }}>it's about creating harmony</span>
                        </p>
                    </div>
                </div>

            </div>
        </div>
    );
}
