import './App.css'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar.js";
import Home from './components/Home.js';
import ShowKitchen from './components/showInterior/ShowKitchen.js';
import ShowBedroom from './components/showInterior/ShowBedroom.js';
import ShowLivingroom from './components/showInterior/ShowLivingroom.js';
import ShowEntryway from './components/showInterior/ShowEntryway.js';
import ShowBathroom from './components/showInterior/ShowBathroom.js';
import ShowCivilWork from './components/showInterior/ShowCivilWork.js';
import ShowPainting from './components/showInterior/ShowPainting.js';
import ShowPopCeiling from './components/showInterior/ShowPopCeiling.js';
import About from './components/About.js';
import Contact from './components/Contact.js';
import CommercialWork from './components/showInterior/ShowCommercialWork.js';
import Footer from './components/Footer.js';
import ShowQuote from './components/ShowQuote.js';
import ScrollToTop from './components/ScrollToTop.js';

function App() {
  return (
    
      <BrowserRouter>
        <Navbar />
        <ScrollToTop/>
     
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/kitchen" element={<ShowKitchen />} />
          <Route path="/bedroom" element={<ShowBedroom />} />
          <Route path="/livingroom" element={<ShowLivingroom />}/>
          <Route path="/entryway" element={<ShowEntryway />} />
          <Route path="/bathroom" element={<ShowBathroom />} />
          <Route path="/painting" element={<ShowPainting />}/>
          <Route path="/civil" element={<ShowCivilWork />} />
          <Route path="/popceiling" element={<ShowPopCeiling />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/commercial" element={<CommercialWork />} />
          <Route path="/quote" element={<ShowQuote />} />

        </Routes>
        <Footer />
      </BrowserRouter>
    
  );
}

export default App;
