import React, { useState, useRef } from 'react'
import Modal from './Modal.js';
import hitIcon1 from '../asset/steps/BookAppoinment.jpg';
import hitIcon2 from '../asset/steps/personalizeDesign.jpg';
import hitIcon3 from '../asset/steps/productionBegin.jpg';
import hitIcon4 from '../asset/steps/moveIn.jpg';
import '../style/Home.css'; 

export default function Process() {
    const [showModal, setShowModal] = useState(false);

    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    const sectionRef = useRef(null);



    return (
        <>
            <div className=" container-fluid " >
                <div className="row px-md-4 px-2  "> <h1>How we work? </h1><p> We start by understanding your needs and goals through in-depth consultations.</p></div>

                <div className="row  px-md-4 px-2 pb-0">
                    <div className={`col-12 col-md-3 `}>
                        <img

                            src={hitIcon1}
                            className="img-fluid mobile-40"
                            alt="Book consultance"
                        />
                        <p className="mt-2"
                            ref={sectionRef}>
                            <span className='h2'>
                                01
                            </span>
                            <span style={{ fontSize: '18px' }}><b> Book consultance</b> </span><br />
                            <span>Begin with a personalized consultation to understand your vision and requirements. </span>
                        </p>
                    </div>
                    <div className={`col-12 col-md-3 `}>
                        <img
                            src={hitIcon2}
                            className="img-fluid mobile-40"
                            alt="Get your design ready"
                        />
                        <p className="mt-2"
                            ref={sectionRef}>
                            <span className="h2">02 </span>
                            <span style={{ fontSize: '18px' }}> <b>Get your design ready</b></span><br />
                            <span>Collaborate to finalize a custom design tailored to your needs and style.</span>
                        </p>
                    </div>


                    <div className={`col-12 col-md-3 `}>
                        <img
                            src={hitIcon3}
                            className="img-fluid mobile-40"
                            alt="Pruduction Begin"
                        />
                        <p className="mt-2"
                            ref={sectionRef} ><span className='h2'>03 </span> 
                            <span style={{ fontSize: '18px' }}> <b>Production begin </b></span> <br />
                            <span>Skilled professionals bring your design to life with precision and quality.</span> </p>
                    </div>
                    <div className={`col-12 col-md-3 `}>
                        <img
                            src={hitIcon4}
                            className="img-fluid mobile-40"
                            alt="Move in"
                        />
                        <p className="mt-2"
                            ref={sectionRef}><span className='h2'>04 </span> <span style={{ fontSize: '18px' }}> <b> Move in </b></span > <br />
                            <span>Step into your beautifully crafted space, ready to enjoy from day one.</span> 
                            </p>
                    </div>
                </div>
                <div className='text-center p-0'>
                    <button className="btn estimateBtn"  onClick={openModal}>Book Free Consultance</button>
                    <Modal showModal={showModal} closeModal={closeModal} />
                </div>
            </div>
        </>
    )
}
