import React from 'react';
import Img1 from '../asset/siddhant.jpg'
import Img2 from '../asset/dharmendra.jpg'

const ShowFurniture = () => {

  return (
    <div className="background-container m-0" style={{ backgroundColor: '#FFFFFD' }}>
      <p className='text-center pt-4' style={{ textDecoration: ' underline' }}>
        <h1 >How it started</h1>
      </p>
      <div className='container text-center pb-5'>

        Hello, I am Siddhant, and I have been passionate about interior design since my childhood. From a young age, I dreamed of becoming a leader in the interior design industry and establishing a renowned company in this field. To pursue this dream, I completed my education at Mumbai University, where I gained a strong foundation for my career. <br /> <br />

        Currently, I am working alongside my father, who has an impressive 20 years of experience in the interior design field. His expertise and guidance have been invaluable as I take steps toward building a successful future in this industry. My deep interest, creativity, and unwavering passion for interior design have motivated me to dedicate myself fully to this profession. <br /> <br />

        With a clear vision in mind, I have taken the exciting step of starting my own company, BeautifizeHome. This venture represents my commitment to delivering exceptional interior design solutions and creating inspiring spaces that reflect the dreams and personalities of my clients. I am thrilled to embark on this journey and work toward establishing BeautifizeHome as a leading name in the interior design industry.
      </div>
      <div className="row m-0 p-0" style={{ backgroundColor: '#EDE4E3' }}>
        <div className="col-12  col-md-6 ps-5  text-center m-0 p-0">
          <img className='mt-5' src={Img1} style={{ height: '100px', borderRadius: '50%' }} alt="Siddhant Vishwakarma" />
          <br />
          Siddhant Vishwakrma
          <br />
          I am a passionate interior designer with a lifelong love for creating inspiring spaces. A graduate of Mumbai University, I combine my academic foundation with hands-on experience gained from working alongside my father, a seasoned professional with 20 years in the industry. Driven by creativity and ambition, I have launched my own company, Beautfy, aiming to deliver exceptional design solutions and establish a prominent presence in the interior design field.
        </div>
        <div className="col-12 col-md-6 text-center  pe-5">
          <img className='mt-5 ' src={Img2} style={{ height: '100px', borderRadius: '50%' }} alt="Dharmendra Vishwakarma" />
          <br /> Dharmendra Vishwakrma <br />
          He is a seasoned interior design professional with over 20 years of experience in the industry. Renowned for his expertise, creativity, and attention to detail, he has successfully completed numerous projects that harmonize functionality and aesthetic appeal. His extensive knowledge and practical insights have made him a respected figure in the field, inspiring many to pursue excellence in interior design. With a commitment to quality and innovation, he continues to leave a lasting impact on every project he undertakes.
        </div>
      </div>
    </div >
  );
};

export default ShowFurniture;
