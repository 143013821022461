import React, { useRef } from 'react';
import starIcon from '../asset/icon.png'; // Import a star icon
import customer1 from '../asset/bed/review.jpg'; // Import your customer images
import customer2 from '../asset/bed/review.jpg';
import customer3 from '../asset/bed/review.jpg';
import customer4 from '../asset/bed/review.jpg';
import customer5 from '../asset/bed/review.jpg';
import customer6 from '../asset/bed/review.jpg';

const HighlightReviews = () => {
    const listRef = useRef(null);

    const scrollLeft = () => {
        if (listRef.current) {
            listRef.current.scrollBy({
                top: 0,
                left: -150,
                behavior: 'smooth',
            });
        }
    };

    const scrollRight = () => {
        if (listRef.current) {
            listRef.current.scrollBy({
                top: 0,
                left: 150,
                behavior: 'smooth',
            });
        }
    };

    const customerImages = [customer1, customer2, customer3, customer4, customer5, customer6];
    const customerNames = [
        'John Doe', 'Jane Smith', 'Alex Johnson', 'Emily Davis', 'Emily Davis', 'Emily Davis'
    ];
    const reviews = [
        'Absolutely love this! The quality exceeded my expectations. Highly recommend!',
        'Great experience, and the product was as described. Would buy again.',
        'The customer service was fantastic, and the product arrived on time.',
        'Beautiful design and great quality. I’m very happy with my purchase.',
        'Beautiful design and great quality. I’m very happy with my purchase.',
        'Beautiful design and great quality. I’m very happy with my purchase.'
    ];
    const ratings = [5, 4, 5, 4]; // Customer ratings

    return (
        <div className='HBody py-4' style={{backgroundColor: '#'}}>
            <div className='text-center highlightHeading m-0 '>
                <p className='h2 m-0 p-0'>BeautifizeHome Family's Review</p>
            </div>
            <div className="scroll-container" >
                <button className="scroll-button left" onClick={scrollLeft}></button>
                <ul className="list-group list-group-horizontal position-relative overflow-auto hide-scrollbar w-100" ref={listRef}>
                    {customerImages.map((img, index) => (
                        <li key={index} className="list-group-item text-center p-4" style={{border: '1px solid black', borderRadius:'7px'}}>
                            <img src={img} alt={`Customer ${index + 1}`} className="img-thumbnail" style={{ height: "100px", width: "100px", borderRadius: "50%" }} />
                            <p className="customer-name" style={{fontSize: '20px', fontWeight: 'bold'}}>{customerNames[index]}</p>
                            <div className="rating mb-2">
                                {Array.from({ length: 5 }, (_, i) => (
                                    <img key={i} src={starIcon} alt="star" style={{ height: '20px', width: '20px', opacity: i < ratings[index] ? 1 : 0.5 }} />
                                ))}
                            </div>
                            <p className="review-description p-0 m-0" style={{ textWrap: 'wrap' }}>{reviews[index]}</p>
                        </li>
                    ))}
                </ul>
                <button className="scroll-button right" onClick={scrollRight}></button>
            </div>
        </div>
    );
};

export default HighlightReviews;
