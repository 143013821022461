import React, { Component } from 'react';
import { Link } from 'react-scroll';
import '../style/Footer.css';
import facebookImage from '../asset/facebook.png';
import twitterImage from '../asset/twitter.png';
import whatsappImage from '../asset/whatsapp.png';
import youtubeImage from '../asset/youtube.png';
import instaImage from '../asset/insta.png'
import logo from '../asset/logo.png';

export default class Footer extends Component {
    render() {
        return (
            <div>
                <hr className=' m-0 p-0'/>
                <div style={{ width: '100%' }}> {/* Set the container to 100% width */}
                    <footer
                        className="text-center text-lg-start text-black"
                        style={{ backgroundColor: '', width: '100%' }} // Ensure footer is 100% width
                    >
                        <div className="p-4 pb-0 " style={{ width: '100%' }}> {/* Removed the 'container' class */}
                            <section className="">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 mb-4 text-center mb-md-0 ms-0 ps-lg-4  ">
                                        <img src={logo} alt="" style={{ height: '40px' }} />
                                        <div className="  px-md-4">
                                            <ul className="list-unstyled mb-0 custom-font d-flex flex-row justify-content-center" style={{ gap: '10px' }}>
                                                <li><a className="py-0" href="https://chat.whatsapp.com/JtqQHdjFG1IHayPVpkX9Fz" role="button" target="_blank" rel="noopener noreferrer"><img src={whatsappImage} alt="Whatsapp Logo" /></a></li>
                                                <li><a className="py-0" href="https://x.com/SMSN_9?t=hqkqzqW7pFJ06RgVpfY9-w&s=08" role="button" target="_blank" rel="noopener noreferrer"><img src={twitterImage} alt="Twitter Logo" /></a></li>
                                                <li><a className="py-0" href="https://www.facebook.com/groups/5715739098472829/?mibextid=HsNCOg" role="button" target="_blank" rel="noopener noreferrer"><img src={facebookImage} alt="Facebook Logo" /></a></li>
                                                <li><a className="py-0" href="https://www.instagram.com/shreematruchhayasevanyas?igsh=MW84cXNiMTBteDg4aw==" role="button" target="_blank" rel="noopener noreferrer"><img src={instaImage} alt="Instagram Logo" /></a></li>
                                                <li><a className="py-0" href="https://youtube.com/@shreematruchhayasevanyas549?si=s-DAe91gx3k2JDOU" role="button" target="_blank" rel="noopener noreferrer"><img src={youtubeImage} alt="Youtube Logo" /></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 mb-4 mb-md-0 ps-lg-5">
                                        <p className="text-uppercase">Design Idea</p>
                                        <div className="dropdown">
                                            <a className="dropdown-toggle text-decoration-none text-dark" href="/" role="button" data-bs-toggle="dropdown" >
                                                <b>Dropdown link</b> 
                                            </a>
                                            <ul className="dropdown-menu">
                                                <li><a className="dropdown-item" href="/">Action</a></li>
                                                <li><a className="dropdown-item" href="/">Another action</a></li>
                                                <li><a className="dropdown-item" href="/">Something else here</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-3 mb-4 mb-md-0 ">
                                        <p className="text-uppercase">QUICK LINK</p>
                                        <ul className="list-unstyled custom-font">
                                            <li className=' fw-bold mt-2'><Link to="ourvision" className="link-hover-effect" duration={500}>Policies</Link></li>
                                            <li className=' fw-bold mt-2'><Link to="aboutUs" className="link-hover-effect" duration={500}>Terms and conditions</Link></li>
                                            <li className=' fw-bold mt-2'><Link to="home" className="link-hover-effect">Refer to Friends</Link></li>
                                            <li className=' fw-bold mt-2'><Link to="hit" className="link-hover-effect" duration={500}>How its work</Link></li>
                                            <li className=' fw-bold mt-2'><Link to="contactUs" className="link-hover-effect" duration={500}>Contact us</Link></li>
                                        </ul>
                                    </div>



                                </div>
                            </section>


                        </div>

                        <div className="text-center align-content-center py-2 m-0" style={{ backgroundColor: '#B482DA', width: '100%' }}>
                            Copyright © 2021 All Rights Reserved
                        </div>
                    </footer>
                </div>
            </div>
        );
    }
}
