import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import img1 from '../../asset/kitchen/k1.jpg'; // Import your images
import img2 from '../../asset/kitchenIsland/k8.jpg';
import img3 from '../../asset/kitchenCabinate/kc1.jpg';
import img4 from '../../asset/dinningTable/dt2.jpg';

import '../../style/Bedroom.css';

const HighlightImages = () => {
    const listRef = useRef(null);

    const scrollLeft = () => {
        if (listRef.current) {
            listRef.current.scrollBy({
                top: 0,
                left: -150,
                behavior: 'smooth',
            });
        }
    };

    const scrollRight = () => {
        if (listRef.current) {
            listRef.current.scrollBy({
                top: 0,
                left: 150,
                behavior: 'smooth',
            });
        }
    };

    const images = [img1, img2, img3, img4];
    const titles = [
        'Kitchen Platform', 'Kitchen Island', 'Cabinate', 'Dining Table'
    ];
    const descriptions = [
        'This is the main work surface in your kitchen, often made of granite, marble, or other materials. ',
        'A freestanding countertop in the middle of your kitchen, often used for cooking, eating, or socializing.',
        'These are storage units with doors and drawers, typically made of wood, used to store kitchenware, dishes, and food.',
        'A dining table is a central piece of furniture where meals are enjoyed, whether it’s daily family dinners, gatherings.'
    ];

    return (
        <div className='HBody'>
            <div className="scroll-container">
                <button className="scroll-button left" onClick={scrollLeft}></button>
                <ul className="list-group list-group-horizontal position-relative overflow-auto hide-scrollbar w-100" ref={listRef}>
                    {images.map((img, index) => (
                        <li key={index} className="list-group-item text-center p-3"  style={{backgroundColor: "#F6F6F5"}}>
                            <Link to={`/showfurniture?title=${titles[index]}&description=${descriptions[index]}&img=${img}`}>
                                <img src={img} alt={`Highlights ${index + 1}`} className="img-thumbnail" style={{ height: "200px", width: "auto", borderRadius: '10px'}} />
                            </Link>
                            <p className="image-title" style={{fontSize: '20px', fontWeight: 'bold'}}>{titles[index]}</p>
                            <p className="image-description p-0 m-0" style={{ textWrap: 'wrap' }}>{descriptions[index]}</p>
                            {titles[index] !== 'Cabinate' && (
                                <Link className='btn view-collection-btn align-content-center p-0 m-0' to={`/kitchen?title=${titles[index]}&description=${descriptions[index]}&img=${img}`} style={{ backgroundColor: '#626262', color: 'white' }}>
                                    View Collection
                                </Link>
                            )}
                        </li>
                    ))}
                </ul>
                <button className="scroll-button right" onClick={scrollRight}></button>
            </div>
        </div>

    );
};

export default HighlightImages;
