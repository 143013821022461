import React, { useState } from 'react';
import Bedroom from './interior/Bedroom.js';
import Kitchen from './interior/Kitchen.js';
import Livingroom from './interior/Livingroom.js';
import Entryway from './interior/Entryway.js';
import Bathroom from './interior/Bathroom.js';
 
import '../style/Home.css'

function KitchenComponent() {
    // Separate states for each button
    const [isCollapsed1, setIsCollapsed1] = useState(true);
    const [isCollapsed2, setIsCollapsed2] = useState(false);
    const [isCollapsed3, setIsCollapsed3] = useState(false);
    const [isCollapsed4, setIsCollapsed4] = useState(false);
    const [isCollapsed5, setIsCollapsed5] = useState(false);
   

    return (
        <div className='container-fluid text-center p-0 '>
            {/* Button 1 */}
            <a className="btn mx-3  rectangle-button align-content-center" style={{ fontSize: isCollapsed1 ? 'x-large' : '' }} onClick={() => setIsCollapsed1(!isCollapsed1)} aria-expanded={isCollapsed1} aria-controls="collapseExample1" data-bs-toggle="collapse" href="#collapseExample1" >
                Kitchen Collection <i className={`fas ${isCollapsed1 ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
            </a>
            <div className={`collapse ${isCollapsed1 ? 'show' : ''}`} id="collapseExample1">
                <section id='kitchen' style={{ backgroundColor: "" }}>
                    <Kitchen />
                </section>
            </div>
            {/* Button 2 */}
            <a className="btn mx-3 rectangle-button align-content-center" style={{ fontSize: isCollapsed2 ? 'x-large' : '' }} onClick={() => { setIsCollapsed2(!isCollapsed2)}} aria-expanded={isCollapsed2} aria-controls="collapseExample2" data-bs-toggle="collapse" href="#collapseExample2" >
                Bedroom Collection <i className={`fas ${isCollapsed2 ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
            </a>
            <div className={`collapse ${isCollapsed2 ? 'show' : ''}`} id="collapseExample2">
                <section id='bedroom' >
                    <Bedroom />
                </section>
            </div>

            {/* Button 3 */}
            <a className="btn mx-3 rectangle-button align-content-center" style={{ fontSize: isCollapsed3 ? 'x-large' : '' }} onClick={() => { setIsCollapsed3(!isCollapsed3) }} aria-expanded={isCollapsed3} aria-controls="collapseExample3" data-bs-toggle="collapse" href="#collapseExample3" >
                Living Room Collection <i className={`fas ${isCollapsed3 ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
            </a>
            <div className={`collapse ${isCollapsed3 ? 'show' : ''}`} id="collapseExample3">
                <section id='livingroom' >
                    <Livingroom />
                </section>
            </div>

            {/* Button 4 */}
            <a className="btn mx-3 rectangle-button align-content-center" style={{ fontSize: isCollapsed4 ? 'x-large' : '' }} onClick={() => { setIsCollapsed4(!isCollapsed4) }} aria-expanded={isCollapsed4} aria-controls="collapseExample4" data-bs-toggle="collapse" href="#collapseExample4" >
                Entryway Collection <i className={`fas ${isCollapsed4 ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
            </a>
            <div className={`collapse ${isCollapsed4 ? 'show' : ''}`} id="collapseExample4">
                <section id='entryway' >
                    <Entryway />
                </section>
            </div>

            {/* Button 5 */}
            <a className="btn mx-3 rectangle-button align-content-center" style={{ fontSize: isCollapsed5 ? 'x-large' : '' }} onClick={() => { setIsCollapsed5(!isCollapsed5) }} aria-expanded={isCollapsed5} aria-controls="collapseExample5" data-bs-toggle="collapse" href="#collapseExample5" >
                Bathroom Collection <i className={`fas ${isCollapsed5 ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
            </a>
            <div className={`collapse ${isCollapsed5 ? 'show' : ''}  m-0 p-0`} id="collapseExample5">
                <section id='bathroom' >
                    <Bathroom />
                </section>
            </div>
        </div>
    );
}

export default KitchenComponent;
