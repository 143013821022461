import React from 'react';

const ShowCivilWork = () => { 
  return (
    <div className="background-container m-0" style={{backgroundColor: '#FFFFFD'}}>
      <div className="container" >
        <h2 className="pt-4 text-center">We Bring Structural Expertise to Interior Design</h2>
        <p>At BeautifizeHome, we go beyond designing beautiful interiors—we also manage the civil form work required to ensure a solid foundation for your dream spaces. Our comprehensive services include both interior design and civil work integration, making us your one-stop solution for creating stunning, functional, and structurally sound environments.</p>
        <h2 className="">What Does This Mean for You?</h2>
        <ul>
          <li>Structural Integrity Meets Design: We align civil works like walls, partitions, ceilings, and flooring with your interior concepts.</li>
          <li>Precision Construction: Our skilled team ensures accurate dimensions, proper leveling, and robust structural changes where necessary.</li>
          <li>Efficient Coordination: With both design and civil work handled by one team, your project is completed more efficiently and without miscommunication between contractors.</li>
        </ul>
        <h2 className="">Our Civil Form Services Include:</h2>
        <ul>
          <li>Demolition and restructuring of spaces.</li>
          <li>Wall modifications, false ceiling installations, and flooring.</li>
          <li>Plumbing and electrical groundwork to support interior design plans.</li>
          <li>Load-bearing assessments and structural adjustments.</li>
        </ul>
        <h2 className="  m-0">Why Choose Us?</h2>
        <p>Whether you’re renovating your home, designing a new office, or creating a retail space, we make sure the foundation is as strong as the style. By integrating civil works into the interior design process, we save you time, money, and hassle while ensuring an impeccable finish.</p>
        <p className=' pb-3 m-0'>Let us handle your project with the expertise it deserves. Contact us today for a consultation!</p>
      </div>
    </div>
  );
};

export default ShowCivilWork;
