import React from 'react';
import icon1 from '../asset/E2EService/icon1.png';
import icon2 from '../asset/E2EService/icon2.png';
import icon3 from '../asset/E2EService/icon3.png';
import icon4 from '../asset/E2EService/icon4.png';
import icon5 from '../asset/E2EService/icon5.png';
import icon6 from '../asset/E2EService/icon6.png';
import icon7 from '../asset/E2EService/icon7.png';
import icon8 from '../asset/E2EService/icon8.png';
import icon9 from '../asset/E2EService/icon9.png';
import icon10 from '../asset/E2EService/icon10.png';
import icon11 from '../asset/E2EService/icon11.png';
import icon12 from '../asset/E2EService/icon12.png';
import icon13 from '../asset/E2EService/icon13.png';
import icon14 from '../asset/E2EService/icon14.png';

const services = [
  { icon: icon1, label: 'Storage and wardrobe' },
  { icon: icon2, label: 'Modular Kitchen' },
  { icon: icon3, label: 'Crockery Units' },
  { icon: icon4, label: 'Space Saving Furniture' },
  { icon: icon5, label: 'TV Units' },
  { icon: icon6,  label: 'Study Tables' },
  { icon: icon7,  label: 'False Ceiling' },
  { icon: icon8,  label: 'Lights' },
  { icon: icon9,  label: 'Wallpaper' },
  { icon: icon10,  label: 'Wall Paint' },
  { icon: icon11,  label: 'Bathroom' },
  { icon: icon12,  label: 'Pooja Unit' },
  { icon: icon13,  label: 'Kid Room' },
  { icon: icon14,  label: 'More' },
];

const ServicesGrid = () => {
  return (
    <div className="container text-center pt-3 ">
      <h2 style={{ color: "#FF6D69", fontWeight: "bold"}}><span style={{ color: "black"}} > End-to-end</span>  interior solutions <br /> for all type of <span style={{ color: "black"}}>interior work</span> </h2>
      <div className="row mt-4 pt-3">
        {services.map((service, index) => (
          <div key={index} className="col-6 col-sm-4 col-md-3 mb-4 ">
            <img src={service.icon} alt={service.label} style={{ width: '50px', height: '50px' }} />
            <p className="mt-2">{service.label}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServicesGrid;
