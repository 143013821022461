import React from 'react';
import logo from '../asset/logo.png';
import { Link, NavLink } from 'react-router-dom';
import '../style/Navbar.css';

export default function Navbar() {
    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
                <div className="container-fluid">
                    <NavLink className="navbar-brand" to="/"><img className="logos" src={logo} alt="Logo" /></NavLink>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="true" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                    <div className="collapse navbar-collapse" id="navbarScroll">
                        <ul className="navbar-nav my-2 my-lg-0 navbar-nav-scroll" style={{ "--bs-scroll-height": "150px" }}>
                            <li className="nav-item"><NavLink className={({ isActive }) => isActive ? "nav-link active-tab" : "nav-link" } to="/kitchen"  >Kitchen</NavLink></li>
                            <li className="nav-item"><NavLink className={({ isActive }) => isActive ? "nav-link active-tab" : "nav-link" } to="/bedroom"  >Wardrobe</NavLink></li>
                            <li className="nav-item"><NavLink className={({ isActive }) => isActive ? "nav-link active-tab" : "nav-link" } to="/livingroom" >TV Units</NavLink></li>
                            <li className="nav-item"><NavLink className={({ isActive }) => isActive ? "nav-link active-tab" : "nav-link" } to="/livingroom"  >Living Room</NavLink></li>
                            <li className="nav-item"><NavLink className={({ isActive }) => isActive ? "nav-link active-tab" : "nav-link" } to="/bedroom"  >Bedroom</NavLink></li>
                            <li className="nav-item"><NavLink className={({ isActive }) => isActive ? "nav-link active-tab" : "nav-link" } to="/bedroom"  >Kid Room</NavLink></li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="/" id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">More</a>
                                <ul className="dropdown-menu" aria-labelledby="navbarScrollingDropdown">
                                    <li><Link className="dropdown-item" to="/civil">Civil Work</Link></li>
                                    <li><Link className="dropdown-item" to="/popceiling">False Ceiling and Pop</Link></li>
                                    <li><Link className="dropdown-item" to="/painting">Painting</Link></li>
                                    <li><Link className="dropdown-item" to="/commercial">Commercial Work</Link></li>
                                    <li><hr className="dropdown-divider" /></li>
                                    <li><Link className="dropdown-item" to="/about">About US</Link></li>
                                    <li><Link className="dropdown-item" to="/contact">Contact Us</Link></li>
                                </ul>
                            </li>
                            <li className="nav-item ms-md-4 ms-sm-0"><a className="nav-link costCalculator p-2" href="/" style={{color: 'black', backgroundColor: '#FF6D69'}}>Get Free Estimate</a></li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
}
