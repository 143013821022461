import React from 'react';

const ShowPainting = () => {

  return (
    <div className="background-container m-0" style={{backgroundColor: '#FFFFFD'}}>
      <div className="container" >
        <h2 className="pt-4 text-center">Mastering the Art of Painting</h2>
        <p>At BeautifizeHome, we understand that the right coat of paint can transform a room, breathe life into a building, and make a powerful first impression. With our expert painting services, we bring precision, creativity, and a deep understanding of colors to every project.</p>
        <h2 className="">Our Painting Services</h2>
        <p>Whether you’re envisioning a vibrant living room, a professional office space, or a polished commercial exterior, our painting solutions are tailored to meet your needs.</p>
        <h4>Interior Painting
        </h4>
        <ul>
          <li>Wall Painting: From bold feature walls to subtle, soothing shades, we ensure a flawless finish that reflects your style.</li>
          <li>Textured Finishes: Add depth and personality with creative textures like stucco, sponging, or metallic effects.</li>
          <li>Accent Walls: Highlight key areas with unique designs or colors that make a statement.</li>
          <li>Trim and Detail Painting: Expertly painted mouldings, baseboards, and trims for a polished look.</li>
        </ul>
        <h4>Exterior Painting</h4>
        <ul>
          <li>Weather-Resistant Coatings: Durable finishes that protect your building from harsh weather and ensure long-lasting beauty.</li>
          <li>Facade Revamps: Modernize or restore your exterior to create an eye-catching appearance.</li>
          <li>Specialized Surface Painting: Painting for wood, metal, and other surfaces to ensure durability and style.</li>
        </ul>
        <h4>Specialty Painting</h4>
        <ul>
          <li>Custom Murals and Artwork: Add a personal touch with hand-painted designs or murals.</li>
          <li>Industrial and Commercial Painting: Tailored solutions for factories, warehouses, or retail spaces.</li>
          <li>Eco-Friendly Painting: Using low-VOC and eco-friendly paints for a healthier, sustainable option.</li>
        </ul>
        <h2 className="">Why Choose Us?</h2>
        <ul>
          <li>Professional Expertise: Our team consists of skilled painters who excel in technique and color mastery.</li>
          <li>High-Quality Materials: We use premium paints and tools to deliver a superior finish every time.</li>
          <li>Tailored Solutions: From concept to completion, we ensure your preferences and requirements are met.</li>
          <li>Attention to Detail: Every stroke is carefully executed to achieve perfection.</li>
          <li>Timely Delivery: We respect your schedule and complete projects on time without compromising quality.</li>
        </ul>
        <h2 className="  m-0">The Power of Colors</h2>
        <p>Colors have the ability to evoke emotions, influence moods, and define spaces. At [Your Company Name], we help you choose the perfect palette that reflects your personality and enhances the purpose of your space.</p>
        <h2>Transform Your Space with Us</h2>
        <p className=' pb-3 m-0'>At [Your Company Name], we are passionate about creating environments that inspire. Whether it’s a residential, commercial, or industrial project, our painting services are designed to bring your vision to life with unparalleled expertise. <br />Contact us today to discuss your painting needs and let us help you create a space that’s vibrant, stylish, and uniquely yours.</p>
      </div>
    </div>
  );
};

export default ShowPainting;
