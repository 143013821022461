import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import img1 from '../../asset/bed/bed20.jpg'; // Import your images
import img2 from '../../asset/bedSide/bed1.jpg';
import img3 from '../../asset/dresser/d11.jpg';
import img4 from '../../asset/wardrobe/w30.jpg';
import img5 from '../../asset/StudyTable/s5.jpg';

import '../../style/Bedroom.css';

const HighlightImages = () => {
    const listRef = useRef(null);

    const scrollLeft = () => {
        if (listRef.current) {
            listRef.current.scrollBy({
                top: 0,
                left: -150,
                behavior: 'smooth',
            });
        }
    };

    const scrollRight = () => {
        if (listRef.current) {
            listRef.current.scrollBy({
                top: 0,
                left: 150,
                behavior: 'smooth',
            });
        }
    };

    const images = [img1, img2, img3, img4, img5];
    const titles = [
        'Bed', 'Bedside Table', 'Dressing Table', 'Wardrobe', 'Study Table'
    ];
    const descriptions = [
        'The primary piece of furniture for comfortable sleeping.', 
        'A small table placed next to a bed, used to hold a lamp, books, or other items.', 
        'A table with a mirror and drawers, used for applying makeup or doing hair.',
        'A large cabinet with shelves and hanging space, used to store clothes.',
        'A study table offers organized workspace for reading and writing tasks.'       
    ];

    return (
        <div className='HBody'>
            
            <div className="scroll-container">
                <button className="scroll-button left" onClick={scrollLeft}></button>
                
                <ul className="list-group list-group-horizontal position-relative overflow-auto hide-scrollbar w-100" ref={listRef}>
                    {images.map((img, index) => (
                        <li key={index} className="list-group-item text-center p-3"  style={{backgroundColor: "#F6F6F5"}}>
                            <Link to={`/showfurniture?title=${titles[index]}&description=${descriptions[index]}&img=${img}`}>
                                <img src={img} alt={`Highlights ${index + 1}`} className="img-thumbnail" style={{ height: "200px", width: "auto", borderRadius: '10px'}} />
                            </Link>
                            <p className="image-title" style={{fontSize: '20px', fontWeight: 'bold'}}>{titles[index]}</p>
                            <p className="image-description p-0 m-0" style={{ textWrap: 'wrap' }}>{descriptions[index]}</p>
                            {titles[index] !== 'Cabinate' && (
                                <Link className='btn view-collection-btn align-content-center p-0 m-0' to={`/bedroom?title=${titles[index]}&description=${descriptions[index]}&img=${img}`} style={{ backgroundColor: '#626262', color: 'white' }}>
                                    View Collection
                                </Link>
                            )}
                        </li>
                    ))}
                </ul>
                <button className="scroll-button right" onClick={scrollRight}></button>
            </div>
        </div>
    );
};

export default HighlightImages;
