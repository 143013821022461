import React, { useState } from 'react';
import Modal from '../Modal.js';
import Img1 from '../../asset/TvUnit/u8.jpg';
import Img2 from '../../asset/TvUnit/u7.jpg';
import Img3 from '../../asset/TvUnit/u6.jpg';
import Img4 from '../../asset/TvUnit/u2.jpg';
import Img5 from '../../asset/TvUnit/u1.jpg';
import Img6 from '../../asset/TvUnit/u3.jpg';
import Img7 from '../../asset/TvUnit/u9.jpg';
import Img8 from '../../asset/TvUnit/u12.jpg';
import Img9 from '../../asset/TvUnit/u11.jpg';



// Sofa
import ImgSofa1 from '../../asset/wardrobe/w30.jpg';
import ImgSofa2 from '../../asset/wardrobe/w17.jpg';
import ImgSofa3 from '../../asset/wardrobe/w14.jpg';
import ImgSofa4 from '../../asset/wardrobe/w8.jpg';
import ImgSofa6 from '../../asset/wardrobe/w23.jpg';
import ImgSofa7 from '../../asset/wardrobe/w25.jpg';
import ImgSofa8 from '../../asset/wardrobe/w27.jpg';
// CoffeeTable
import ImgCoffeeTable1 from '../../asset/coffeeTable/8.jpg';
import ImgCoffeeTable2 from '../../asset/coffeeTable/main.jpg';
import ImgCoffeeTable3 from '../../asset/coffeeTable/7.jpg';
import ImgCoffeeTable4 from '../../asset/coffeeTable/4.jpg';
import ImgCoffeeTable5 from '../../asset/coffeeTable/2.jpg';
import ImgCoffeeTable6 from '../../asset/coffeeTable/1.jpg';
import ImgCoffeeTable7 from '../../asset/coffeeTable/3.jpg';
import ImgCoffeeTable8 from '../../asset/coffeeTable/5.jpg';
import ImgCoffeeTable9 from '../../asset/coffeeTable/6.jpg';
import ImgCoffeeTable10 from '../../asset/coffeeTable/10.jpg';

// Display Cabinate
import ImgDisplayCabinate1 from '../../asset/dresser/d3.jpg';

const ShowFurniture = () => {

  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const kitchen = [
    [
      {
        id: 1,
        title: 'Modern Bed',
        heading: 'TV Unit',
        description: 'A stylish and comfortable modern bed for your bedroom.',
        img: Img1,
      },
      {
        id: 2,
        title: 'Luxury Sofa',
        heading: 'TV Unit',
        description: 'A luxury sofa with soft cushions for ultimate comfort.',
        img: Img2,
      },
      {
        id: 3,
        title: 'Wooden Wardrobe',
        heading: 'TV Unit',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: Img3,
      },
      {
        id: 4,
        title: 'Wooden Wardrobe',
        heading: 'TV Unit',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: Img4,
      },
      {
        id: 5,
        title: 'Wooden Wardrobe',
        heading: 'TV Unit',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: Img5,
      },
      {
        id: 6,
        title: 'Wooden Wardrobe',
        heading: 'TV Unit',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: Img6,
      },
      {
        id: 7,
        title: 'Wooden Wardrobe',
        heading: 'TV Unit',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: Img7,
      },
      {
        id: 8,
        title: 'Wooden Wardrobe',
        heading: 'TV Unit',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: Img8,
      },
      {
        id: 9,
        title: 'Wooden Wardrobe',
        heading: 'TV Unit',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: Img9,
      }
      
    ],
    [
      {
        id: 1,
        title: 'Modern Bed',
        heading: 'Sofa',
        description: 'A stylish and comfortable modern bed for your bedroom.',
        img: ImgSofa1,
      },
      {
        id: 2,
        title: 'Luxury Sofa',
        heading: 'Sofa',
        description: 'A luxury sofa with soft cushions for ultimate comfort.',
        img: ImgSofa2,
      },
      {
        id: 3,
        title: 'Wooden Wardrobe',
        heading: 'Sofa',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: ImgSofa3,
      },
      {
        id: 4,
        title: 'Wooden Wardrobe',
        heading: 'Sofa',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: ImgSofa4,
      },
      {
        id: 5,
        title: 'Wooden Wardrobe',
        heading: 'Sofa',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: ImgSofa6,
      },
      {
        id: 6,
        title: 'Wooden Wardrobe',
        heading: 'Sofa',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: ImgSofa7,
      },
      {
        id: 7,
        title: 'Wooden Wardrobe',
        heading: 'Sofa',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: ImgSofa8,
      }
    ],
    [
      {
        id: 1,
        title: 'Modern Bed',
        heading: 'Coffee table',
        description: 'A stylish and comfortable modern bed for your bedroom.',
        img: ImgCoffeeTable1,
      },
      {
        id: 2,
        title: 'Luxury Sofa',
        heading: 'Coffee table',
        description: 'A luxury sofa with soft cushions for ultimate comfort.',
        img: ImgCoffeeTable2,
      },
      {
        id: 3,
        title: 'Wooden Wardrobe',
        heading: 'Coffee table',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: ImgCoffeeTable3,
      },
      {
        id: 4,
        title: 'Wooden Wardrobe',
        heading: 'Coffee table',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: ImgCoffeeTable4,
      },
      {
        id: 5,
        title: 'Wooden Wardrobe',
        heading: 'Coffee table',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: ImgCoffeeTable5,
      },
      {
        id: 6,
        title: 'Coffee table',
        heading: 'Coffee table',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: ImgCoffeeTable6,
      },
      {
        id: 7,
        title: 'Coffee table',
        heading: 'Coffee table',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: ImgCoffeeTable7,
      },
      {
        id: 8,
        title: 'Coffee table',
        heading: 'Coffee table',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: ImgCoffeeTable8,
      },
      {
        id: 9,
        title: 'Coffee table',
        heading: 'Coffee table',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: ImgCoffeeTable9,
      },
      {
        id: 10,
        title: 'Coffee table',
        heading: 'Coffee table',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: ImgCoffeeTable10,
      }
    ],
    [
      {
        id: 1,
        title: 'Modern Bed',
        heading: 'Display Cabinate',
        description: 'A stylish and comfortable modern bed for your bedroom.',
        img: ImgDisplayCabinate1,
      }
    ]
  ];
  return (
    <div style={{ backgroundColor: '#ff8683' }}>
      <div className="container">
        <div className="row g-4">
          {kitchen.map((category, index) => (
            <React.Fragment key={index}>
              {/* Print category heading */}
              <h2 className="col-12 text-center pt-3">
                {category[0]?.heading || "Default Heading"}
              </h2>
              {category.map((item) => (
                <div key={item.id} className="col-12 col-sm-6 col-md-4 col-lg-3">
                  <div className="card" style={{ backgroundColor: '#B482DA' }}>
                    <img className="card-img-top lazy" height={250}  src={item.img} alt={item.title} />
                    <div className="card-body">
                      <h5 className="card-title">{item.title}</h5>
                      <p className="card-text">{item.description}</p>
                      <button
                        className="btn estimateBtn my-2 mt-md-4"
                        style={{
                          backgroundColor: "#626262",
                          borderRadius: "8px",
                          display: "inline-block",
                          color: "white",
                        }}
                        onClick={openModal}
                      >
                        Get Quote
                      </button>
                      <Modal showModal={showModal} closeModal={closeModal} />
                    </div>
                  </div>
                </div>
              ))}
              {/* Insert <hr> after each category */}
              <div className="col-12 ">
                <hr className='p-0 m-0' />
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ShowFurniture;
