import React from 'react';

const ShowFurniture = () => {

  return (
    <div className="background-container m-0" style={{ backgroundColor: '#FFFFFD' }}>
      <div className="container" >
        <h2 className="pt-4 text-center">Comprehensive Interior Design Solutions for Residential and Commercial Spaces</h2>
        <p>At BeautifizeHome, we specialize in transforming spaces into functional and inspiring environments. While we’re known for creating stunning residential interiors, our expertise extends far beyond homes. We are equally passionate about designing exceptional commercial spaces that reflect your brand identity and enhance productivity.</p>
        <h2 className="">Commercial Interior Design Expertise</h2>
        <p>We understand that every commercial space serves a unique purpose, and our designs are tailored to meet those needs. Whether it’s an office, retail store, restaurant, or hospitality project, we create interiors that balance functionality, aesthetics, and brand storytelling.</p>
        <h4>Our Commercial Services Include:
        </h4>
        <ul>
          <li>Office Spaces: Designing workspaces that boost productivity, encourage collaboration, and create a comfortable, professional environment.</li>
          <li>Retail Stores: Crafting eye-catching interiors that draw customers in and enhance their shopping experience.</li>
          <li>Restaurants and Cafes: Creating atmospheres that complement your cuisine and leave a lasting impression on your patrons.</li>

        </ul>
        <h4>Why Partner with Us?</h4>
        <ul>
          <li>Weather-Resistant Coatings: Durable finishes that protect your building from harsh weather and ensure long-lasting beauty.Custom Designs: Each project is tailored to meet the unique requirements and personality of your business.</li>
          <li>Efficient Space Planning: We maximize space utilization without compromising on aesthetics.</li>
          <li>Brand Integration: Your commercial interior will be a reflection of your brand values and vision.</li>
          <li>Timely Execution: We respect your timelines and ensure the project is completed as scheduled.</li>
          <li>End-to-End Solutions: From concept to completion, we manage every detail, so you can focus on your business.</li>
        </ul>

        <h2 className="">Transforming Ideas into Impactful Spaces</h2>


        <p className=' pb-3 m-0'>At BeautifizeHome, we believe that great design is the key to creating memorable experiences. Whether you’re looking to revamp your workplace, open a new retail outlet, or elevate your hospitality space, we bring creativity, precision, and professionalism to every project. <br />Contact us today to discuss how we can make your commercial space a true reflection of your business and a standout in your industry.

        </p>
      </div>
    </div>
  );
};

export default ShowFurniture;
