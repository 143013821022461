import React, { useState } from 'react'
import Logo from '../asset/logo.png'

export default function Fqa() {
    const [isHomeCollapsed1, setisHomeCollapsed1] = useState(false);
    const [isHomeCollapsed2, setisHomeCollapsed2] = useState(false);
    const [isHomeCollapsed3, setisHomeCollapsed3] = useState(false);
    const [isHomeCollapsed4, setisHomeCollapsed4] = useState(false);
    const [isHomeCollapsed5, setisHomeCollapsed5] = useState(false);
    const [isHomeCollapsed6, setisHomeCollapsed6] = useState(false);
    const [isHomeCollapsed7, setisHomeCollapsed7] = useState(false);
    const [isHomeCollapsed9, setisHomeCollapsed9] = useState(false);
    const [isHomeCollapsed10, setisHomeCollapsed10] = useState(false);
    const [isHomeCollapsed11, setisHomeCollapsed11] = useState(false);
    const [isHomeCollapsed12, setisHomeCollapsed12] = useState(false);
    const [isHomeCollapsed14, setisHomeCollapsed14] = useState(false);



    return (
        <>
            <section>
                <div className='container-fluid'>
                    <div className="row" style={{ fontFamily: '"Overpass", Sans-serif' }}>
                        <div className="col-12 col-md-5 n px-3 ps-md-5 p-0 mt-4" >
                            <div className='d-inline-block m-0  my-md-auto '>

                                <div className='text-center pt-md-5 p-0'>
                                    <img src={Logo} className=' m-0 mt-md-5' alt="Logo" height={50} width={280} style={{}} />
                                </div>
                                <div className="d-flex justify-content-center pt-3">
                                    <p className="text-justify text-align-justify" >
                                        Transforming spaces into stunning, functional works of art, we offer end-to-end interior design solutions. From concept to execution, our in-house team handles every detail, including renovations and construction, ensuring impeccable quality. Whether reimagining small spaces or designing grand projects, we bring your vision to life with personalized designs, tailored to your style and needs. Let us craft a space that inspires and elevates your everyday living.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-7 p-md-5 pt-md-5 p-2'>
                            <p style={{ fontSize: '30px' }}>FQA</p>
                            {/* btn1 */}
                            <div>
                                <a className="btn m-0 p-0 rectangle-button align-content-center d-flex justify-content-between" onClick={() => setisHomeCollapsed1(!isHomeCollapsed1)} aria-expanded={isHomeCollapsed1} aria-controls="collapseHomeExample1" data-bs-toggle="collapse" href="#collapseHomeExample1" >
                                    1. What services does your company provide?
                                    <i className={`pe-1 fas ${isHomeCollapsed1 ? 'fa-minus' : 'fa-plus'}`}></i>
                                </a>
                                <hr className="m-1" />
                            </div>

                            <div className={`collapse ${isHomeCollapsed1 ? 'show' : ''}`} id="collapseHomeExample1">
                                We offer a wide range of interior design services, including residential and commercial design, space planning, furniture selection, lighting design, color consultation, and project management.
                            </div>
                            {/* btn2 */}
                            <div>
                                <a className="btn m-0 p-0 rectangle-button align-content-center d-flex justify-content-between" onClick={() => setisHomeCollapsed2(!isHomeCollapsed2)} aria-expanded={isHomeCollapsed2} aria-controls="collapseHomeExample2" data-bs-toggle="collapse" href="#collapseHomeExample2" >
                                    2. Do you handle both residential and commercial projects?
                                    <i className={`pe-1 fas ${isHomeCollapsed2 ? 'fa-minus' : 'fa-plus'}`}></i>
                                </a>
                                <hr className="m-1" />
                            </div>
                            <div className={`collapse ${isHomeCollapsed2 ? 'show' : ''}`} id="collapseHomeExample2">
                                Yes, we specialize in designing both residential homes and commercial spaces such as offices, retail stores, and hospitality venues.                            </div>
                            {/* btn3 */}
                            <div>
                                <a className="btn m-0 p-0 rectangle-button align-content-center d-flex justify-content-between" onClick={() => setisHomeCollapsed3(!isHomeCollapsed3)} aria-expanded={isHomeCollapsed3} aria-controls="collapseHomeExample3" data-bs-toggle="collapse" href="#collapseHomeExample3" >
                                    3. Do you offer free consultations?
                                    <i className={`pe-1 fas ${isHomeCollapsed3 ? 'fa-minus' : 'fa-plus'}`}></i>
                                </a>
                                <hr className="m-1" />
                            </div>
                            <div className={`collapse ${isHomeCollapsed3 ? 'show' : ''}`} id="collapseHomeExample3">
                                Yes, we provide an initial consultation to discuss your needs, preferences, and project requirements. This allows us to understand your vision and provide tailored recommendations.
                            </div>
                            {/* btn4 */}
                            <div>
                                <a className="btn m-0 p-0 rectangle-button align-content-center d-flex justify-content-between" onClick={() => setisHomeCollapsed4(!isHomeCollapsed4)} aria-expanded={isHomeCollapsed4} aria-controls="collapseHomeExample4" data-bs-toggle="collapse" href="#collapseHomeExample4" >
                                    4. Can you work with my existing furniture and decor?
                                    <i className={`pe-1 fas ${isHomeCollapsed4 ? 'fa-minus' : 'fa-plus'}`}></i>
                                </a>
                                <hr className="m-1" />
                            </div>
                            <div className={`collapse ${isHomeCollapsed4 ? 'show' : ''}`} id="collapseHomeExample4">
                                Absolutely! We can incorporate your existing pieces into the design plan to create a cohesive look while respecting your preferences and budget.
                            </div>
                            {/* btn5 */}
                            <div>
                                <a className="btn m-0 p-0 rectangle-button align-content-center d-flex justify-content-between" onClick={() => setisHomeCollapsed5(!isHomeCollapsed5)} aria-expanded={isHomeCollapsed5} aria-controls="collapseHomeExample5" data-bs-toggle="collapse" href="#collapseHomeExample5" >
                                    5. What is your design process?
                                    <i className={`pe-1 fas ${isHomeCollapsed5 ? 'fa-minus' : 'fa-plus'}`}></i>
                                </a>
                                <hr className="m-1" />
                            </div>
                            <div className={`collapse ${isHomeCollapsed5 ? 'show' : ''}`} id="collapseHomeExample5">
                                Our process involves: 1.Initial consultation to understand your needs. 2.Concept development, including mood boards and sketches. 3.Design presentation with detailed plans. 4.Execution and project management to bring the design to life.
                            </div>
                            {/* btn6 */}
                            <div>
                                <a className="btn m-0 p-0 rectangle-button align-content-center d-flex justify-content-between" onClick={() => setisHomeCollapsed6(!isHomeCollapsed6)} aria-expanded={isHomeCollapsed6} aria-controls="collapseHomeExample6" data-bs-toggle="collapse" href="#collapseHomeExample6" >
                                    6. How long does a typical project take?
                                    <i className={`pe-1 fas ${isHomeCollapsed6 ? 'fa-minus' : 'fa-plus'}`}></i>
                                </a>
                                <hr className="m-1" />
                            </div>
                            <div className={`collapse ${isHomeCollapsed6 ? 'show' : ''}`} id="collapseHomeExample6">
                                The timeline varies depending on the project’s complexity. A small room redesign might take a few weeks, while a full home or commercial project could take several months.
                            </div>
                            {/* btn7 */}
                            <div>
                                <a className="btn m-0 p-0 rectangle-button align-content-center d-flex justify-content-between" onClick={() => setisHomeCollapsed7(!isHomeCollapsed7)} aria-expanded={isHomeCollapsed7} aria-controls="collapseHomeExample7" data-bs-toggle="collapse" href="#collapseHomeExample7" >
                                    7. Do you handle renovations or construction?
                                    <i className={`pe-1 fas ${isHomeCollapsed7 ? 'fa-minus' : 'fa-plus'}`}></i>
                                </a>
                                <hr className="m-1" />
                            </div>
                            <div className={`collapse ${isHomeCollapsed7 ? 'show' : ''}`} id="collapseHomeExample7">
                                Yes, we manage all aspects of renovations and construction ourselves. Our in-house team of skilled professionals ensures quality and seamless execution throughout the entire process.              </div>

                            {/* btn9 */}
                            <div>
                                <a className="btn m-0 p-0 rectangle-button align-content-center d-flex justify-content-between" onClick={() => setisHomeCollapsed9(!isHomeCollapsed9)} aria-expanded={isHomeCollapsed9} aria-controls="collapseHomeExample9" data-bs-toggle="collapse" href="#collapseHomeExample9" >
                                    8. Will I be involved in the design process?
                                    <i className={`pe-1 fas ${isHomeCollapsed9 ? 'fa-minus' : 'fa-plus'}`}></i>
                                </a>
                                <hr className="m-1" />
                            </div>
                            <div className={`collapse ${isHomeCollapsed9 ? 'show' : ''}`} id="collapseHomeExample9">
                                Yes, your input is invaluable. We collaborate closely with you throughout the process to ensure the design aligns with your vision.
                            </div>
                            {/* btn10 */}
                            <div>
                                <a className="btn m-0 p-0 rectangle-button align-content-center d-flex justify-content-between" onClick={() => setisHomeCollapsed10(!isHomeCollapsed10)} aria-expanded={isHomeCollapsed10} aria-controls="collapseHomeExample10" data-bs-toggle="collapse" href="#collapseHomeExample10" >
                                    9. How much do your services cost? <i className={`pe-1 fas ${isHomeCollapsed10 ? 'fa-minus' : 'fa-plus'}`}></i>
                                </a>
                                <hr className="m-1" />
                            </div>
                            <div className={`collapse ${isHomeCollapsed10 ? 'show' : ''}`} id="collapseHomeExample10">
                                Our pricing depends on the scope and scale of the project. We offer customized packages and can provide a detailed quote after an initial consultation.
                            </div>
                            {/* btn11 */}
                            <div>
                                <a className="btn m-0 p-0 rectangle-button align-content-center d-flex justify-content-between" onClick={() => setisHomeCollapsed11(!isHomeCollapsed11)} aria-expanded={isHomeCollapsed11} aria-controls="collapseHomeExample11" data-bs-toggle="collapse" href="#collapseHomeExample11" >
                                    10. Do you provide warranty? <i className={`pe-1 fas ${isHomeCollapsed11 ? 'fa-minus' : 'fa-plus'}`}></i>
                                </a>
                                <hr className="m-1" />
                            </div>
                            <div className={`collapse ${isHomeCollapsed11 ? 'show' : ''}`} id="collapseHomeExample11">
                                Yes, we provide a warranty for our services/products.
                            </div>
                            {/* btn12 */}
                            <div>
                                <a className="btn m-0 p-0 rectangle-button align-content-center d-flex justify-content-between" onClick={() => setisHomeCollapsed12(!isHomeCollapsed12)} aria-expanded={isHomeCollapsed12} aria-controls="collapseHomeExample12" data-bs-toggle="collapse" href="#collapseHomeExample12" >
                                    11. What styles do you specialize in?
                                    <i className={`pe-1 fas ${isHomeCollapsed12 ? 'fa-minus' : 'fa-plus'}`}></i>
                                </a>
                                <hr className="m-1" />
                            </div>
                            <div className={`collapse ${isHomeCollapsed12 ? 'show' : ''}`} id="collapseHomeExample12">
                                Our team is versatile and experienced in various styles, including modern, contemporary, traditional, minimalist, industrial, and more.              </div>

                            {/* btn14 */}
                            <div>
                                <a className="btn m-0 p-0 rectangle-button align-content-center d-flex justify-content-between" onClick={() => setisHomeCollapsed14(!isHomeCollapsed14)} aria-expanded={isHomeCollapsed14} aria-controls="collapseHomeExample14" data-bs-toggle="collapse" href="#collapseHomeExample14" >
                                    12. How do I get started? <i className={`pe-1 fas ${isHomeCollapsed14 ? 'fa-minus' : 'fa-plus'}`}></i>
                                </a>
                                <hr className="m-1" />
                            </div>
                            <div className={`collapse ${isHomeCollapsed14 ? 'show' : ''}`} id="collapseHomeExample14">
                                Simply contact us through our website or call us to schedule your consultation. We’ll guide you through the next steps.
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
