import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate


export default function Modal({ showModal, closeModal }) {
    const modalRef = useRef(null);
    const navigate = useNavigate(); // Initialize navigate hook

    useEffect(() => {
        const modalElement = document.getElementById('exampleModal');

        // Ensure we initialize the modal only if the element exists and ref is not set
        if (modalElement && !modalRef.current) {
            modalRef.current = new window.bootstrap.Modal(modalElement, {
                backdrop: 'static', // optional, adjust based on behavior you want
                keyboard: false // optional, adjust based on behavior you want
            });
        }

        if (showModal) {
            // Show the modal if `showModal` is true and modalRef has been initialized
            modalRef.current?.show();
        } else {
            // Hide the modal if `showModal` is false
            modalRef.current?.hide();
        }

        // Cleanup function to ensure we don’t leave any Bootstrap modals initialized after unmount
        return () => {
            if (modalRef.current) {
                modalRef.current.hide();
                modalRef.current = null; // Clear modal reference after hiding
            }
        };
    }, [showModal]);

    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        name: "",
        phone: "",
        whatsappUpdates: false,
        email: "",
        propertyName: "",
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    const handleNext = () => setStep(2);
    const handleBack = () => setStep(1);
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Form Data Submitted:", formData);
        navigate('/quote'); // Change '/thank-you' to your desired route
    };

    return (
        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
            <div className="modal-dialog modal-fullscreen-sm-down">
                <div className="modal-content ">
                    <div className="modal-header  ">
                        <h5 className="modal-title " id="exampleModalLabel">Book Free Consultance</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}></button>
                    </div>
                    <div className="modal-body">

                        <div className="container d-flex justify-content-left  mt-2  m-0">
                            <form onSubmit={handleSubmit} className="form-card bg-light p-4 mx-auto" style={{ width: "400px", height: '450px', borderRadius: "10px" }}>
                                <div className="d-flex justify-content-between align-items-center mb-4">
                                    <h4 className="mb-0">{step === 1 ? "Designs for Every Budget" : "Share your email and property details"}</h4>
                                    <div className="step-indicator">{step}/2</div>
                                </div>
                                {step === 1 ? (
                                    <>
                                        <div className="mb-4">
                                            <input type="text" className="form-control" name="name" placeholder="Name" value={formData.name} onChange={handleChange} required />
                                        </div>
                                        <div className="mb-4 d-flex align-items-center">
                                            <input type="tel" className="form-control" name="phone" placeholder="Phone number" value={formData.phone} onChange={handleChange} required />
                                        </div>
                                        <div className="form-check mb-4">
                                            <input type="checkbox" className="form-check-input" id="whatsappUpdates" name="whatsappUpdates" checked={formData.whatsappUpdates} onChange={handleChange} />
                                            <label className="form-check-label" htmlFor="whatsappUpdates">Send me updates on WhatsApp</label>
                                        </div>
                                        <button type="button" className="btn btn-danger w-100" onClick={handleNext}>Get Free Quote</button>
                                    </>
                                ) : (
                                    <>
                                        <div className="mb-4">
                                            <input type="email" className="form-control" name="email" placeholder="Email ID" value={formData.email} onChange={handleChange} required />
                                        </div>
                                        <div className="mb-4">
                                            <input type="text" className="form-control" name="propertyName" placeholder="Property Name" value={formData.propertyName} onChange={handleChange} required />
                                        </div>
                                        <button type="button" className="btn btn-secondary w-100 mb-4" onClick={handleBack}>Back</button>
                                        <button type="submit" className="btn btn-danger w-100" onClick={closeModal}>Submit</button>
                                    </>
                                )}
                                <p className="text-center mt-3 small">
                                    By submitting this form, you agree to the <a href="/">privacy policy</a> & <a href="/">terms and conditions</a>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
