import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Carousel from './Carousel';
import "../style/Home.css";
// import Footer from './Footer.js';
import EtoESolution from './EtoESolution.js'
import Review from './Review.js';
import Collection from './Collection.js'
import Process from './Process.js'
import Fqa from './Fqa.js';
import HorizontalReviewScroll from './HorizontalReviewScroll.js';
import ServiceIcon1 from '../asset/ServiceIcon/kitchen.png';
import ServiceIcon2 from '../asset/ServiceIcon/closet.png';
import ServiceIcon3 from '../asset/ServiceIcon/workspace.png';
import ServiceIcon4 from '../asset/ServiceIcon/renovation.png';
import ServiceIcon5 from '../asset/ServiceIcon/electrician.png';
import ServiceIcon6 from '../asset/ServiceIcon/engineer.png';
import ServiceIcon7 from '../asset/ServiceIcon/ceiling.png';
import ServiceIcon8 from '../asset/ServiceIcon/paint-roller.png';


import '../style/Home.css'

export default function Home() {

  const reviews = [
    {
      name: "John Doe",
      image: "https://via.placeholder.com/60",
      rating: 4,
      text: "Great product! I highly recommend it."
    },
    {
      name: "Jane Smith",
      image: "https://via.placeholder.com/60",
      rating: 5,
      text: "Fantastic experience. Will buy again!"
    },
    {
      name: "Jane Smith",
      image: "https://via.placeholder.com/60",
      rating: 5,
      text: "Fantastic experience. Will buy again!"
    }, {
      name: "Jane Smith",
      image: "https://via.placeholder.com/60",
      rating: 5,
      text: "Fantastic experience. Will buy again!"
    }, {
      name: "Jane Smith",
      image: "https://via.placeholder.com/60",
      rating: 5,
      text: "Fantastic experience. Will buy again!"
    },
    // More reviews
  ];
  const sectionRef = useRef(null);
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setAnimate(true);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, { threshold: 0.5 });

    if (sectionRef.current) observer.observe(sectionRef.current);

    return () => observer.disconnect();
  }, []);
  return (
    <>

      <Carousel />
      <section className=' py-5 m-0' style={{ backgroundColor: "#F6F6F5" }}>
        <div className="container " >
          <h2>
            What we offer?
          </h2>
          <div className="row">
            <div className="col-md-6 py-3 ">
              <div className="card h-100 d-flex flex-column offer" style={{ width: '100%' }}>
                <div className="card-body">
                  <p className="card-title" style={{fontSize: '23px', fontWeight: 'bold'}}>Services</p>
                  <ul className='ps-3' style={{ listStyleType: 'none' }}>
                    <li><img src={ServiceIcon6} alt="" style={{ width: '20px', height: '20px', marginRight: '8px' }} /> Civil work</li>
                    <li><img src={ServiceIcon3} alt="" style={{ width: '20px', height: '20px', marginRight: '8px' }} /> Complete interior work</li>
                    <li><img src={ServiceIcon5} alt="" style={{ width: '20px', height: '20px', marginRight: '8px' }} /> Electric work</li>
                    <li><img src={ServiceIcon4} alt="" style={{ width: '20px', height: '20px', marginRight: '8px' }} /> Home Renovation</li>
                    <li><img src={ServiceIcon1} alt="" style={{ width: '20px', height: '20px', marginRight: '8px' }} /> Modular kitchen</li>
                    <li><img src={ServiceIcon2} alt="" style={{ width: '20px', height: '20px', marginRight: '8px' }} /> Modular wardrobe</li>
                    <li><img src={ServiceIcon7} alt="" style={{ width: '20px', height: '20px', marginRight: '8px' }} /> Pop and False ceiling</li>
                    <li><img src={ServiceIcon8} alt="" style={{ width: '20px', height: '20px', marginRight: '8px' }} /> Painting</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-6 py-3 ">
              <div className="card h-100 d-flex flex-column offer" style={{ width: '100%' }}>
                <div className="card-body">
                  <p className="card-title" style={{fontSize: '23px', fontWeight: 'bold'}}>Benefits</p>
                  <ul>
                    <li className='p-1'>Price-match gurantee- price match to a valid quote in comparison with a branded player and for exact scope</li>
                    <li className='p-1'>On time delivery- Punctuality is our promise; we deliver work right on schedule.</li>
                    <li className='p-1'>Economical- Stylish interiors at prices for every budget.</li>
                    <li className='p-1'>No hidden cost- Transparent costing without last-minute additions.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div

          className={`text-center p-0 m-0 `}
        >
          <h2 className="mt-3 px-2" style={{ color: "#FF6D69", fontWeight: "bold" }}>
            Beautify Your Space,<br /> Perfected{" "}
            <span style={{ color: "black" }}>by Skilled Hands.</span>
          </h2>
          <div className={` ${animate ? "animate-bottom" : ""}`} >

            <div ref={sectionRef} className={`mx-auto p-3 `} style={{backgroundColor: '#ffffff', fontSize: "20px", color: "black", width: "80%", border: "1px solid black", textAlign: "justify", borderRadius: "10px", }} >
              At BeautifizeHome, we specialize in transforming interiors into stunning
              spaces with the artistry and dedication of our exceptional team. Our highly
              skilled labor force ensures every detail is thoughtfully crafted, blending
              beauty with functionality. Whether it’s a cozy home or a sleek office, we
              bring your vision to life with expertise, quality materials, and aModal
              commitment to excellence.

            </div>

          </div>
          <Link  to="/quote" className="btn estimateBtn zoom-effect my-2 mt-md-4" style={{ backgroundColor: "#FF6D69", padding: "15px 30px", fontSize: "20px", borderRadius: "8px", display: "inline-block", color: ' black'}} >Book Free Consultance</Link>
        </div>
      </section>

      <section className='' >
        <EtoESolution />
      </section>

      <Collection />
      <section className='mt-5' style={{ backgroundColor: "" }}>
        <Process />
      </section>


      <Fqa />
      <section className='align-content-center text-center px-0 py-4 p-sm-5' >
      <a href="/" className="btn zoom-effect my-2 mt-md-4" style={{ backgroundColor: "#FF6D69", padding: "15px 30px", fontSize: "20px", borderRadius: "8px", display: "inline-block", color: 'black'}} >
              Book Free Consultation
            </a>
        <div className='  text-center p-0 m-0 mx-auto' style={{   color: "black" }}>
          Bring Your Vision to Life – Start with a Free Consultation! <br />
          <p className='h4' style={{  }}>
            We offer a complimentary consultation to explore your needs, preferences, and project goals. Let’s discuss your ideas and provide personalized recommendations to turn them into reality.
          </p>
        </div>
      </section>
      <HorizontalReviewScroll />
      <Review reviews={reviews} />
      
    </>
  );
}
