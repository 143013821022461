import React from 'react';
import '../style/Home.css'; // Importing the custom CSS for the scrolling effect 
import supplierLogo1 from '../asset/supplierLogo/pidilite.avif';
import supplierLogo2 from '../asset/supplierLogo/jaguar.svg';
import supplierLogo3 from '../asset/supplierLogo/RR-Kabel.svg';
import supplierLogo4 from '../asset/supplierLogo/Finolex.avif';
import supplierLogo5 from '../asset/supplierLogo/Hettich.avif';
import supplierLogo6 from '../asset/supplierLogo/Asian-Paints.avif';
import supplierLogo7 from '../asset/supplierLogo/century.png';
 
const HorizontalImageScroll = () => {
  const images = [
    supplierLogo1,
    supplierLogo2,
    supplierLogo3,
    supplierLogo4,
    supplierLogo5,
    supplierLogo6,
    supplierLogo7
  ];

  return (
    <div className="container-fluid py-5">
      <h1 className="text-center  ">
        Suppliers
      </h1>
      <p className="text-center mb-4">
        We procure materials from leading international suppliers.
      </p>
      <div className="review-container">
        <div className="reviews ">
          {/* Render images twice to enable circular scrolling */}
          {images.concat(images).map((image, index) => (
            <div className="align-content-center py-auto mx-4" key={index}  >
              <img src={image} alt={`Review ${index + 1}`} height={50} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HorizontalImageScroll;
