import React, { useState } from 'react';
import Modal from '../Modal.js';
import Img1 from '../../asset/bed/b31.jpg';
import Img2 from '../../asset/bed/b35.jpg';
import Img3 from '../../asset/bed/bed10.jpg';
import Img4 from '../../asset/bed/bed19.jpg';
import Img5 from '../../asset/bed/bed13.jpg';
import Img6 from '../../asset/bed/bed17.jpg';
import Img7 from '../../asset/bed/bed18.jpg';
import Img8 from '../../asset/bed/bed19.jpg';
import Img9 from '../../asset/bed/bed15.jpg';
import Img10 from '../../asset/bed/bed20.jpg';
import Img11 from '../../asset/bed/bed6.jpg';
import Img12 from '../../asset/bed/bed22.jpg';
// Wardrobe
import Imgwardrobe1 from '../../asset/wardrobe/w30.jpg';
import Imgwardrobe2 from '../../asset/wardrobe/w17.jpg';
import Imgwardrobe3 from '../../asset/wardrobe/w14.jpg';
import Imgwardrobe4 from '../../asset/wardrobe/w8.jpg';
import Imgwardrobe6 from '../../asset/wardrobe/w23.jpg';
import Imgwardrobe7 from '../../asset/wardrobe/w25.jpg';
import Imgwardrobe8 from '../../asset/wardrobe/w27.jpg';
// Bed side table
import ImgBedsideTable1 from '../../asset/bedSide/3.jpg';
import ImgBedsideTable2 from '../../asset/bedSide/4.jpg';
import ImgBedsideTable3 from '../../asset/bedSide/5.jpg';
import ImgBedsideTable4 from '../../asset/bedSide/6.jpg';
import ImgBedsideTable5 from '../../asset/bedSide/bed1.jpg';
import ImgBedsideTable6 from '../../asset/bedSide/bed2.jpg';
// Dreasing table
import ImgDreasingTable1 from '../../asset/dresser/d3.jpg';
import ImgDreasingTable2 from '../../asset/dresser/d6.jpg';
import ImgDreasingTable3 from '../../asset/dresser/d9.jpg';
import ImgDreasingTable4 from '../../asset/dresser/d11.jpg';
import ImgDreasingTable5 from '../../asset/dresser/d13.jpg';
import ImgDreasingTable6 from '../../asset/dresser/d15.jpg';
import ImgDreasingTable7 from '../../asset/dresser/d5.jpg';
const ShowFurniture = () => {

  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const kitchen = [
    [
      {
        id: 1,
        title: 'Modern Bed',
        heading: 'Bed Design',
        description: 'A stylish and comfortable modern bed for your bedroom.',
        img: Img1,
      },
      {
        id: 2,
        title: 'Luxury Sofa',
        heading: 'Bed Design',
        description: 'A luxury sofa with soft cushions for ultimate comfort.',
        img: Img2,
      },
      {
        id: 3,
        title: 'Wooden Wardrobe',
        heading: 'Bed Design',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: Img3,
      },
      {
        id: 4,
        title: 'Wooden Wardrobe',
        heading: 'Bed Design',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: Img4,
      },
      {
        id: 5,
        title: 'Wooden Wardrobe',
        heading: 'Bed Design',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: Img5,
      },
      {
        id: 6,
        title: 'Wooden Wardrobe',
        heading: 'Bed Design',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: Img6,
      },
      {
        id: 7,
        title: 'Wooden Wardrobe',
        heading: 'Bed Design',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: Img7,
      },
      {
        id: 8,
        title: 'Wooden Wardrobe',
        heading: 'Bed Design',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: Img8,
      },
      {
        id: 9,
        title: 'Wooden Wardrobe',
        heading: 'Bed Design',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: Img9,
      },
      {
        id: 10,
        title: 'Wooden Wardrobe',
        heading: 'Bed Design',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: Img10,
      },
      {
        id: 11,
        title: 'Wooden Wardrobe',
        heading: 'Bed Design',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: Img11,
      },
      {
        id: 12,
        title: 'Wooden Wardrobe',
        heading: 'Bed Design',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: Img12,
      }
    ],
    [
      {
        id: 1,
        title: 'Modern Bed',
        heading: 'Wardrobe Design',
        description: 'A stylish and comfortable modern bed for your bedroom.',
        img: Imgwardrobe1,
      },
      {
        id: 2,
        title: 'Luxury Sofa',
        heading: 'Wardrobe Design',
        description: 'A luxury sofa with soft cushions for ultimate comfort.',
        img: Imgwardrobe2,
      },
      {
        id: 3,
        title: 'Wooden Wardrobe',
        heading: 'Wardrobe Design',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: Imgwardrobe3,
      },
      {
        id: 4,
        title: 'Wooden Wardrobe',
        heading: 'Wardrobe Design',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: Imgwardrobe4,
      },
      {
        id: 5,
        title: 'Wooden Wardrobe',
        heading: 'Wardrobe Design',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: Imgwardrobe6,
      },
      {
        id: 6,
        title: 'Wooden Wardrobe',
        heading: 'Wardrobe Design',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: Imgwardrobe7,
      },
      {
        id: 7,
        title: 'Wooden Wardrobe',
        heading: 'Wardrobe Design',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: Imgwardrobe8,
      }
    ],
    [
      {
        id: 1,
        title: 'Modern Bed',
        heading: 'Bedside Table',
        description: 'A stylish and comfortable modern bed for your bedroom.',
        img: ImgBedsideTable1,
      },
      {
        id: 2,
        title: 'Luxury Sofa',
        heading: 'Bedside Table',
        description: 'A luxury sofa with soft cushions for ultimate comfort.',
        img: ImgBedsideTable2,
      },
      {
        id: 3,
        title: 'Wooden Wardrobe',
        heading: 'Bedside Table',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: ImgBedsideTable3,
      },
      {
        id: 4,
        title: 'Wooden Wardrobe',
        heading: 'Bedside Table',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: ImgBedsideTable4,
      },
      {
        id: 5,
        title: 'Wooden Wardrobe',
        heading: 'Bedside Table',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: ImgBedsideTable5,
      },
      {
        id: 6,
        title: 'Bedside Table',
        heading: 'Bedside Table',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: ImgBedsideTable6,
      }
    ],
    [
      {
        id: 1,
        title: 'Modern Bed',
        heading: 'Dressing table',
        description: 'A stylish and comfortable modern bed for your bedroom.',
        img: ImgDreasingTable1,
      },
      {
        id: 2,
        title: 'Luxury Sofa',
        heading: 'Dressing table',
        description: 'A luxury sofa with soft cushions for ultimate comfort.',
        img: ImgDreasingTable2,
      },
      {
        id: 3,
        title: 'Wooden Wardrobe',
        heading: 'Dressing table',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: ImgDreasingTable3,
      },
      {
        id: 4,
        title: 'Wooden Wardrobe',
        heading: 'Dressing table',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: ImgDreasingTable4,
      },
      {
        id: 5,
        title: 'Wooden Wardrobe',
        heading: 'Dressing table',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: ImgDreasingTable5,
      },
      {
        id: 6,
        title: 'Wooden Wardrobe',
        heading: 'Dressing table',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: ImgDreasingTable6,
      },
      {
        id: 7,
        title: 'Wooden Wardrobe',
        heading: 'Dressing table',
        description: 'A spacious wooden wardrobe with multiple compartments.',
        img: ImgDreasingTable7,
      }
    ]
  ];
  return (
    <div style={{ backgroundColor: '#ff8683' }}>
      <div className="container">
        <div className="row g-4">
          {kitchen.map((category, index) => (
            <React.Fragment key={index}>
              {/* Print category heading */}
              <h2 className="col-12 text-center pt-3">
                {category[0]?.heading || "Default Heading"}
              </h2>
              {category.map((item) => (
                <div key={item.id} className="col-12 col-sm-6 col-md-4 col-lg-3">
                  <div className="card" style={{ backgroundColor: '#B482DA' }}>
                    <img className="card-img-top lazy" height={250}  src={item.img} alt={item.title} />
                    <div className="card-body">
                      <h5 className="card-title">{item.title}</h5>
                      <p className="card-text">{item.description}</p>
                      <button
                        className="btn estimateBtn my-2 mt-md-4"
                        style={{
                          backgroundColor: "#626262",
                          borderRadius: "8px",
                          display: "inline-block",
                          color: "white",
                        }}
                        onClick={openModal}
                      >
                        Get Quote
                      </button>
                      <Modal showModal={showModal} closeModal={closeModal} />
                    </div>
                  </div>
                </div>
              ))}
              {/* Insert <hr> after each category */}
              <div className="col-12 ">
                <hr className='p-0 m-0' />
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ShowFurniture;
