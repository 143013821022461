import React, { useState} from 'react';
import { useLocation } from 'react-router-dom';
import Modal from '../Modal.js';
const ShowFurniture = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const title = params.get('title');
  const description = params.get('description');
  // const img = params.get('img');
  const products = [
    {
      id: 1,
      title: 'Modern Bed',
      description: 'A stylish and comfortable modern bed for your bedroom.',
      img: 'https://via.placeholder.com/300x200',
    },
    {
      id: 2,
      title: 'Luxury Sofa',
      description: 'A luxury sofa with soft cushions for ultimate comfort.',
      img: 'https://via.placeholder.com/300x200',
    },
    {
      id: 3,
      title: 'Wooden Wardrobe',
      description: 'A spacious wooden wardrobe with multiple compartments.',
      img: 'https://via.placeholder.com/300x200',
    },
    {
      id: 4,
      title: 'Wooden Wardrobe',
      description: 'A spacious wooden wardrobe with multiple compartments.',
      img: 'https://via.placeholder.com/300x200',
    },
    {
      id: 5,
      title: 'Wooden Wardrobe',
      description: 'A spacious wooden wardrobe with multiple compartments.',
      img: 'https://via.placeholder.com/300x200',
    },
    {
      id: 6,
      title: 'Wooden Wardrobe',
      description: 'A spacious wooden wardrobe with multiple compartments.',
      img: 'https://via.placeholder.com/300x200',
    },
    {
      id: 7,
      title: 'Modern Bed',
      description: 'A stylish and comfortable modern bed for your bedroom.',
      img: 'https://via.placeholder.com/300x200',
    },
    {
      id: 8,
      title: 'Luxury Sofa',
      description: 'A luxury sofa with soft cushions for ultimate comfort.',
      img: 'https://via.placeholder.com/300x200',
    },
    {
      id: 9,
      title: 'Wooden Wardrobe',
      description: 'A spacious wooden wardrobe with multiple compartments.',
      img: 'https://via.placeholder.com/300x200',
    }
  ];
  return (
    <div>

      {/* {img && <img src={img} alt={title} />} */}
      <div className="container my-5">
        <h2 className="text-center mb-4">{title}</h2>
        <p className="text-center mb-4">{description}</p>
        {/* Using raw Bootstrap grid system without React-Bootstrap components */}
        <div className="row g-4">
          {products.map((product) => (
            <div key={product.id} className="col-12 col-sm-6 col-md-4 col-lg-3">
              <div className="card">
                <img className="card-img-top" src={product.img} alt={product.title} />
                <div className="card-body">
                  <h5 className="card-title">{product.title}</h5>
                  <p className="card-text">{product.description}</p>
                  <button className="btn estimateBtn my-2 mt-md-4" style={{ backgroundColor: "#626262", borderRadius: "8px", display: "inline-block", color: 'white' }}  onClick={openModal}>Get Quote</button>
                    <Modal showModal={showModal} closeModal={closeModal} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ShowFurniture;
